.related {
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: 0px 20px 0px 20px;
}

.product_card {
  max-width: 300px;
  margin: 20px 0;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.product_box {
  position: absolute;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.6);
  opacity: 0;
  transition: 0.5s;
}

.product_box:hover {
  opacity: 1;
}

.product_box h2 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
  color: #ffffff;
  font-size: 22px;
}

.product_card span {
  color: #3fe030;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product_box p {
  font-size: 14px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #ffffff;
}

.row_btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.row_btn a {
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  letter-spacing: 2px;
  padding: 6px;
}
.row_btn a:hover {
  color: white;
  text-decoration: none;
}

#btn_buy {
  border-radius: 2px;
  margin-right: 5px;
  background: #000000;
  border: 1px solid #f5f5f5;
}
#btn_buy:hover {
  background: #2e2e2e;
}
#btn_view {
  border-radius: 2px;
  background-color: #f5f5f5;
  color: #000000;
  margin-left: 5px;
}
#btn_view:hover {
  background: #e0e0e0;
}
.product_card input {
  position: absolute;
  width: 25px;
  height: 25px;
}
