@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
.home-page {
  overflow: hidden;
  background-color: #1a1e23;
}

.homepage-combo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.testomonies,
.socialmedia {
  display: flex;
  align-items: center;
  background-color: #1a1e23;
}

.testimonies-group,
.contact-group {
  background-color: rgb(35, 41, 47);
  background-color: whitesmoke;
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url(/static/media/cpu-min.5396774b.jpeg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.testimonies-group h2 {
  display: flex;
  justify-content: center;
  margin: auto;
}

.contact-group {
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url(/static/media/cpu-min.5396774b.jpeg);
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vh;
  justify-content: center;
}

.subTitle {
  font-size: 8rem;
  margin: 0 auto;
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #206a5d;
  letter-spacing: 1rem;
  display: flex;
  align-items: center;
  padding: 10rem;
}

.email {
  font-size: 8rem !important;
}

.email:hover {
  font-size: 9rem !important;
  cursor: pointer;
  filter: drop-shadow(0 0 1.5rem white);
}

.header-button {
  border: 2px solid #fff;
  color: white;
  box-sizing: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 1.8em;
  margin-bottom: 10px;
  padding: 10px 10px;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}

.header-button {
  animation: header-btn 10s steps(1) -1s normal;
}
@keyframes header-btn {
  0% {
    left: 250%;
  }
  100% {
    left: 0%;
  }
}

.header-button:hover {
  background-color: #204740;
  text-decoration: none;
  transition: 0.5s color ease;
  color: #fff;
}

.header-username,
.header-username2,
.header-career,
.header-scroll {
  color: white;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.header-scroll {
  position: fixed;
  top: 95%;
  width: 100%;
  margin-top: 20rem;
}

/* Image Overlay End */

.projects {
  width: 100%;
}

.projects h1,
.technologies h1,
.color h1,
.articles h1,
.socialmedia h1,
.open-section h1 {
  color: white;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  font-size: 4rem;
  letter-spacing: 1rem;
  line-height: 40px;
  margin: 0;
}

.technologies {
  background-color: #1a1e23;
}

.contact {
  background-color: #1a1e23;
  padding-top: 2.5rem;
  margin-top: 2rem;
}

.btn-input {
  margin-top: 1rem;
  font-size: 1rem;
  border-color: #206a5d;
  border: #206a5d;
  text-decoration: none;
  background-color: #206a5d;
  color: #ebecf1;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
  border-radius: 8%;
}

.btn-input:hover {
  margin-top: 1rem;
  font-size: 1.2rem;
  border-color: #206a5d;
  border: #206a5d;
  text-decoration: underline;
  background-color: #206a5d;
  color: #ebecf1;
  padding: 4px 8px 4px 8px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
  border-radius: 8%;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .homepage-combo {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .socialmedia {
    width: 80%;
  }
  .testomonies {
    width: 85%;
  }
  .subTitle {
    font-size: 3.5rem;
  }

  .header-button {
    margin: auto !important;
    width: 40% !important;
  }

  .contact-group h2 {
    font-size: 3.5rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .twitter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.twitter-timeline {
  height: 90vh !important;
}

.personal-container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-items: center;
  background-color: #1a1e23;
  background-color: rgb(35, 41, 47);
  width: 80%;
  margin: auto;
}

.personalBrand-opener {
  background: rgb(35, 41, 47);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 80%;
}

.personalBrand-opener span {
  color: white;
  text-shadow: 2px 2px 2px black;
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  -webkit-text-decoration-color: rgb(235, 183, 65);
          text-decoration-color: rgb(235, 183, 65);
}

.personalBrand-opener .name-title {
  margin: auto;
  text-align: center;
  margin-top: 3rem;
  letter-spacing: 0.2rem;
  font-size: 3.5rem;
  font-weight: 400;
  color: whitesmoke;
  text-shadow: 2px 2px 2px #204740;
  animation: grow 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
@keyframes grow {
  to {
    transform: scale(3);
  }
}
.bio-info {
  width: 75%;
  margin-top: 1rem;
  font-size: 2.7rem;
  font-weight: 600;
  text-align: center;
  color: rgb(235, 183, 65);
  text-shadow: 2px 2px 2px #204740;
}

.envelop {
  display: none;
}

.fade {
  transition: opacity 2.15s linear !important;
}

.personal-combo {
  display: flex;
  width: 100%;
  height: 70vh;
}

.personal-card {
  background-color: whitesmoke;
  margin: 0 4rem 5rem 0rem;
  height: 55vh;
  overflow: scroll;
}

.personal-card2 {
  padding: 3rem;
  color: whitesmoke;
  font-size: 40rem;
  margin-top: -10%;
  overflow: scroll;
}

.clickHere {
  position: absolute;
  top: 40%;
  left: 30%;
  z-index: -1;
}

.personal-card-title {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 400;
}
.personal-card-info {
  margin: 5rem 2rem 5rem 2rem;
  height: 1vh;
}
.personal-card-content {
  overflow: scroll;
  font-size: 2.2rem;
}
.personal-image {
  width: 45%;
  height: 55vh;
  margin-bottom: 1rem;
  margin: 0 3rem 10rem 3rem;
  object-fit: cover;
  image-rendering: pixelated !important;
}
.personal-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: pixelated !important;
}
.closed-envelop:hover {
  filter: drop-shadow(0 0 1.5rem gray);
}

.personal-btn {
  border: 2px solid #204740;
  color: #204740;
  box-sizing: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 1.8em;
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  left: 35%;
  overflow: hidden;
  animation: personal-btn 10s steps(1) -1s normal;
}

.personal-btn:hover {
  background-color: #204740;
  color: #fff;
}

.personal-btn-wrapper {
  display: flex;
  justify-content: space-around;
}

@keyframes peronal-btn {
  0% {
    left: 250%;
  }
  100% {
    left: 0%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 600px) {
  .bio-info {
    width: 90%;
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: rgb(235, 183, 65);
    text-shadow: 2px 2;
  }
  .personal-container {
    width: 100%;
    height: 100%;
  }
  .personal-combo {
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-bottom: 2rem;
    width: 100vw;
    background-color: rgb(35, 41, 47);
  }
  .personal-image {
    width: 80%;
    height: 80%;
  }

  .personal-card {
    width: 80%;
    height: 50vh;
    margin: auto;
  }
  .personal-btn-wrapper {
    display: none;
  }

  .bio-info {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .personal-container {
    width: 100%;
    height: 100%;
  }
  .personal-combo {
    display: grid;
    grid-template-columns: 1fr;
  }
  .personal-image {
    width: 80%;
    margin: auto;
    padding-bottom: 2rem;
  }
  .personal-card {
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
  }
  .personal-card-info {
    margin: 2rem;
  }
}

@media only screen and (max-width: 1030px) {
  .personal-combo {
    display: block;
    height: auto;
  }
  .personal-combo img {
    width: -webkit-fill-available;
  }
}

.button--secondary {
  color: white;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.button--large {
  font-size: 16px;
  padding: 12px 24px;
}
.button--round {
  border-radius: 50% !important;
  height: 4rem !important;
  width: 3rem !important;
}
.testimonial-carousel {
  width: 650px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.testimonial-carousel .item {
  color: #999;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  min-height: 340px;
}

.testimonial-carousel .img-box {
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border-radius: 50%;
}

.testimonial-carousel .img-box img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}

.testimonial-carousel .testimonial-descp {
  padding: 30px 0 10px;
}

.testimonial-descp {
  color: #fff;
  font-size: 2rem;
  width: 90%;
  text-align: center;
}

.testimonial-carousel .testimonial-title {
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

.testimonial-carousel .testimonial-title b {
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
  display: block;
  padding-bottom: 5px;
}
.testimonial-carousel .carousel-control {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #999;
  text-shadow: none;
  top: 4px;
}

.testimonial-carousel-control i {
  font-size: 1.3em;
  margin-right: 2px;
}

.carousel-control.left {
  left: auto;
  right: 40px;
}

.carousel-control.right i {
  margin-right: -2px;
}

.carousel .carousel-indicators {
  bottom: 15px;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 11px;
  height: 11px;
  margin: 1px 5px;
  border-radius: 50%;
}

.carousel-indicators li {
  border-color: transparent;
}

.carousel-indicators li.active {
  border: none;
  background: #888;
}

.carousel-indicators {
  margin: 0 auto !important;
  width: 100%;
}

.tech-container {
  background-color: rgb(35, 41, 47);
  background-color: whitesmoke;
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url(/static/media/keyboard-min.012f1481.jpeg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 40vh;
}

.tech-title {
  font-size: 8rem;
  margin: 0 auto;
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #206a5d;
  letter-spacing: 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 6%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 7));
  }
}
.client-slider {
  background: whitesmoke;
  background: transparent;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.client-slider::before,
.client-slider::after {
  background: linear-gradient(
    to right,
    #1a1f23 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.client-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.client-slider::before {
  left: 0;
  top: 0;
}
.client-slider .client-slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 11);
}
.client-slider .client-slide {
  height: 100px;
}

.pinklemonade {
  border-radius: 12px;
}

.client-slide {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 768px) {
  .tech-title {
    font-size: 3rem;
  }
}

@media only screen and (max-device-width: 1024px) {
  .client-slider {
    top: 50%;
  }
}
@media only screen and (max-device-width: 768px) {
  .client-slider {
    top: 35%;
  }
}

/* Project Containers */

.project-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  margin-top: 5rem;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-link {
  color: #fff;
  border-color: #fff;
}

.portfolio-link:hover {
  text-decoration: none;
  border-color: #206a5d;
}

/* Project Containers */

@media only screen and (min-device-width: 375px) and (max-device-width: 600px) {
  .p-container {
    grid-template-columns: 1fr !important;
  }
}

.header-career {
  color: white;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 5rem;
  letter-spacing: 1.5rem;
  line-height: 40px;
}
/* ------------------------------------------ */

@media only screen and (min-width: 768px) {
  .header-career {
    font-size: 2.5rem;
    letter-spacing: 1.5rem;
    line-height: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .header-career {
    color: transparent !important;
  }
}

.notification-wrapper {
  position: fixed;
  top: 5%;
  right: 10px;
  width: 300px;
  z-index: 1;
}

.notification-item {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification-item.exit {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
}

.notification-item .bar {
  height: 10px;
}

.notification-item.success .bar {
  background-color: #65d266;
}

.notification-item.error .bar {
  background-color: red;
}

.header2 {
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url(/static/media/project1-min.c0ce7f1a.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 40vh;
  margin: 0;
}
/* Tags */
.tags {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 1%;
  left: 10px;
  position: absolute;
  width: 1%;
  top: 10px;
}

.tag::after {
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: #206a5d;
  color: white;
}

.tag:hover::after {
  border-left-color: #206a5d;
}

/* Tags end */

.project-box {
  position: relative;
  left: 10%;
  background-color: white;
  width: 80%;
  display: flex;
  flex-direction: column;
}
.project-container {
  color: black;
  background-color: #1a1e23;
}
.project-desciption {
  position: relative;
  left: 0%;
}
@media only screen and (max-width: 480px) {
}

/*──────────────────
     Skeleton
──────────────────*/

.post {
  display: flex;
  width: 100%;
}

.post .left-col {
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.5rem;
}

.post .avatar {
  width: 75px;
  height: 75px;
  background-color: #eaeaea;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.post .avatar-skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
}

.post .avatar img {
  width: 70%;
  height: 70%;
}

.post .right-col {
  flex: 1 1;
}

/*──────────────────
     Login
──────────────────*/

.login span:hover {
  text-decoration: underline;
  cursor: pointer;
}

/*──────────────────
      Tabs
──────────────────*/

/* Tabs*/
#tabs {
  padding: 60px 0;
}
#tabs {
  color: #eee;
}
#tabs h6.section-title {
  color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.tab-active {
  color: #f3f3f3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid !important;
  font-size: 1.2em;
  font-weight: bold;
  opacity: 0.5;
  text-decoration: none;
  padding: 2%;
}

#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #eee;
  font-size: 1.2em;
}

.blog-tabs {
  display: flex;
  justify-content: space-between;
}

.nav-item .nav-link {
  text-decoration: none;
}

/*──────────────────
      articles
──────────────────*/

.article-box {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 2rem;
  margin-top: 5rem;
  padding: 0% 4% 10% 4%;
}

.article-header {
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url(/static/media/project1-min.c0ce7f1a.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 40vh;
}
.article-container {
  text-align: center;
  color: white;
  background-color: #1a1e23;
  overflow: hidden;
}

.articles-header {
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 5rem;
}
.article-content {
  background-color: #23292f;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

#articles {
  margin-top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.article-category-container {
  margin-top: 4rem;
  background-color: #23292f;
}

.article-categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 100%;
}
.tag-category {
  list-style: none;
  padding: 5rem;
  margin: 0%;
  background-color: lightgray;
  border: 1px solid black;
}
.tag-category:hover {
  color: #1a1e23;
  cursor: pointer;
}
.article-card {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 1rem !important;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}

.article-card .work-content {
  text-align: center;
  padding: 10px 5px;
  font-size: 2rem;
  color: white;
  font-family: var(--main-font-family);
  cursor: pointer;
}

.article-line {
  background-color: lightgray;
  opacity: 0.5;
  width: 95%;
  margin: 2rem;
}
.popular {
  background-color: #23292f;
  text-align: center;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 1.5rem;
  color: white;
  font-family: var(--main-font-family);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 55%;
  border-radius: 12px;
}
.page-link {
  background-color: #23292f !important;
  color: white !important;
}
.popular-articles {
  margin: auto;
}

.article-card-header {
  color: white;
  font-size: 4rem;
  text-decoration: none;
}

.article-card-header:hover {
  text-decoration: none;
  transition: 0.25s;
  color: #204740;
}

.article-card:hover {
  box-shadow: 0 0 1.5rem gray;
}

.article-card-meta {
  font-size: 2rem;
}

.popular-link,
.popular-articles a {
  text-decoration: none;
  color: white;
}

.popular-link:hover,
.popular-articles a:hover {
  text-decoration: underline;
  color: #204740;
}

.articleList {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-column-start: 1;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
}

.article-search {
  background-color: #1a1e23;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 50%;
  text-align: center;
  font-size: 3rem;
  margin-top: 5rem;
  color: white;
}

.article-search:focus {
  outline: none;
}
/* Category Tags */
.main-tag-container-article {
  list-style: none;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.main-tag-article:hover {
  text-decoration: none;
  color: white;
  opacity: 0.6;
  cursor: default;
}
.main-tag-article::before {
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 2%;
  left: 10px;
  position: absolute;
  width: 2%;
  top: 10px;
  background-color: white;
}

.main-category {
  display: flex;
  justify-content: center;
  align-items: centers;
}
.main-category h3 {
  margin-right: 20px;
}
.main-tag-article {
  border: 2px solid #fff;
  color: white;
  opacity: 0.6;
  box-sizing: inherit;
  cursor: pointer;
  border-radius: 12px !important;
  display: inline-block;
  font-size: 1em;
  padding: 0 22px 0 25px;
  margin: 0 10px 10px 0;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  background-color: gray;
  font-size: larger;
  height: 26px;
  line-height: 26px;
  position: relative;
  -webkit-transition: color 0.2s;
}

/* Category Tags */

.article-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 12px;
}

.article-btn-info {
  border: 2px solid #fff;
  color: white;
  box-sizing: inherit;
  cursor: pointer;
  font-size: 0.8em;
  padding: 10px 20px;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  text-transform: uppercase;
}
.article-btn {
  animation: header-btn 10s steps(1) -1s normal;
}
@keyframes header-btn {
  0% {
    left: 250%;
  }
  100% {
    left: 0%;
  }
}

.article-btn-info:hover {
  background-color: #204740;
  text-decoration: none;
  border-color: #204740;
  font-size: 0.8em;
  transition: 0.5s color ease;
  color: rgb(235, 183, 65);
}

/*
@media screen and (min-width: 768px) {
    .articleList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1.5rem ;
    }
} */

/* @media screen and (min-width: 992px) {
    .articleList {
        width: 85vw;
        grid-template-columns: repeat(2, 1fr);
    }
} */

/* @media screen and (min-width: 2000px) {
    .articleList {
        grid-template-columns: repeat(2, 1fr);
	}

} */

.blog-content {
  display: flex;
  background-color: white;
  width: 100%;
  font-family: sans-serif;
}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
}

.top-header.menu-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 20px;
}

.top-header.fix-search {
  background: #eee;
}

.search {
  position: absolute;
  top: 155px;
  left: 20px;
  right: 20px;
}

.search input {
  width: 265px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 15px;
  transition: width 0.2s;
  -webkit-appearance: none;
}

.search.fix-search {
  position: fixed;
  top: 10px;
}

.search.fix-search input {
  width: 250px;
}

.top {
  height: 250px;
  padding-top: 40px;
}
.hero {
  width: 200px;
  height: 100px;
  background: #ccc;
  margin: 0 auto;
}

.blog-main p {
  font-size: 2rem;
}

.blog-sub {
  background-color: white !important;
}

.blog-card p {
  color: black;
}
.blog-feature {
  opacity: 0.6;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.03rem;
}
.blog-icon {
  font-size: 2.3rem;
}
.blog-heading {
  margin: 0 auto;
  display: contents;
}

#terminal {
  align-self: flex-start;
}

.blog-categories {
  position: relative;
  top: 42%;
  right: 30%;
}
.blog-categories h6 {
  text-transform: uppercase;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .popular,
  .subscribe-container,
  .article-sidebar-container {
    display: none !important;
  }

  .article-box {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .article-sidebar {
    display: none;
  }
  .article-box {
    grid-template-columns: 1fr;
    padding: 4%;
  }
  .article-card {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .article-box {
    padding: 8%;
  }

  .article-header,
  .blog-tabs,
  #tabs {
    display: none;
  }
}

.subscribe-link {
  margin: 0 auto;
  border-radius: 12px;
}

.subscribe-container {
  display: flex;
  flex-direction: column;
  background: rgb(35, 41, 47);
  color: white;
  margin-bottom: 15rem;
  padding: 1rem;
  border-radius: 12px;
  height: auto;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.subscribe-container h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.subscribe-container p {
  text-align: center;
  margin: 30px;
  color: white;
  font-size: 1.7rem;
}

.subscribe-container div input {
  margin: 10px;
  padding: 10px;
  border-radius: 12px;
  width: 70%;
  border-color: white;
}

.subscribe-container div input::placeholder {
  font-size: 1.5rem;
}

.skeleton {
  background: #ddd;
  margin: 10px 0;
  border-radius: 4px;
}

.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  position: relative;
  background: #f2f2f2;
  border-radius: 1%;
  animation: loading 2.5s infinite;
}

.skeleton-wrapper.profile {
  height: 100vh;
}

.skeleton-wrapper.profile .skeleton-card {
  padding: 5%;
}

.skeleton-profile .skeleton.avatar {
  width: 300px;
  height: 300px;
  padding-bottom: 5%;
}

.skeleton.text {
  width: 100%;
  height: 12px;
}

.skeleton.title {
  width: 50%;
  height: 20px;
  margin-bottom: 15px;
}

.skeleton.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.skeleton-blog {
  background: #23292f;
  border-radius: 5%;
  height: 100%;
}

.shimmer {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5%;
  /* transform: skewX(-20deg); */
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transition: translateX(-150%);
  }
  50% {
    transition: translateX(-60%);
  }
  100% {
    transition: translateX(150%);
  }
}

.pagination {
  font-size: 2rem !important;
}

.page-item.active .page-link {
  border-color: white !important;
  background-color: #206a5d !important;
}

.disabled {
  opacity: 0.5;
}

.load-page {
  background: white;
  overflow: hidden;
}

.loader {
  width: 300px;
  height: 300px;
  margin: auto;
}

.loader div {
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  border: 2px solid #fff;
  border-top: 2px solid lavender;
  border-radius: 50%;
  animation: rotate 10s linear infinite alternate-reverse;
}

@keyframes rotate {
  50% {
    transform: rotate(80deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.related {
  display: flex;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 20px 0px 20px;
}

.product_card {
  max-width: 300px;
  margin: 20px 0;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.product_box {
  position: absolute;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.6);
  opacity: 0;
  transition: 0.5s;
}

.product_box:hover {
  opacity: 1;
}

.product_box h2 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
  color: #ffffff;
  font-size: 22px;
}

.product_card span {
  color: #3fe030;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product_box p {
  font-size: 14px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #ffffff;
}

.row_btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.row_btn a {
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  letter-spacing: 2px;
  padding: 6px;
}
.row_btn a:hover {
  color: white;
  text-decoration: none;
}

#btn_buy {
  border-radius: 2px;
  margin-right: 5px;
  background: #000000;
  border: 1px solid #f5f5f5;
}
#btn_buy:hover {
  background: #2e2e2e;
}
#btn_view {
  border-radius: 2px;
  background-color: #f5f5f5;
  color: #000000;
  margin-left: 5px;
}
#btn_view:hover {
  background: #e0e0e0;
}
.product_card input {
  position: absolute;
  width: 25px;
  height: 25px;
}

.products {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 90vh;
  padding: 0px 20px 0px 20px;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

.delete-all {
  text-align: right;
  margin: 20px;
}

.delete-all input {
  height: 25px;
  width: 25px;
  transform: translateY(5px);
  margin: 0 15px;
}

.delete-all span {
  text-transform: uppercase;
  color: rgb(3, 165, 206);
  letter-spacing: 1.3px;
}

.delete-all button {
  border: 1px solid crimson;
  padding: 10px 25px;
  color: crimson;
  text-transform: uppercase;
}

.filter_menu {
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
}

.filter_menu section,
input {
  border: 1px solid #ccc;
  padding: 0 10px;
  outline: none;
  height: 40px;
}

.filter_menu input {
  flex: 1 1;
  margin: 0 10px;
}

.load_more {
  text-align: center;
}

.load_more button {
  padding: 10px 25px;
  margin-bottom: 20px;
  border: 1px solid #555;
  text-transform: capitalize;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 99;
}

.logo-loader {
  height: 35rem;
  width: 35rem;
}

.loading-bar {
  width: 30rem;
  height: 2px;
  background: #cfcfcf;
  margin: 22px;
  position: relative;
  overflow: hidden;
}

.loading-bar::before {
  content: "";
  width: 68px;
  height: 2px;
  background: #050a30;
  position: absolute;
  left: -34px;
  animation: bluebar 1.5s infinite ease;
}

@keyframes bluebar {
  50% {
    left: 25rem;
  }
}

.shop-header {
  width: auto;
  min-height: 70px;
  align-items: baseline;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  min-width: 300px;
}

.shop-header .logo {
  flex: 1 1;
}

.shop-header a {
  text-transform: uppercase;
  color: #555;
}

.shop-header ul li {
  display: inline-block;
  opacity: 0.7;
  padding: 10px;
}

.shop-header ul li:hover {
  opacity: 1;
}

.menu {
  display: none;
}

.cart-icon {
  position: relative;
  margin-right: 10px;
  margin-top: 20px;
}
.cart-icon span {
  background: crimson;
  border-radius: 20px;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 7px;
  font-size: 10px;
  z-index: 1;
}
.cart-icon a {
  position: absolute;
  right: 0;
}
#search {
  outline: none;
  border: none;
  display: inline-block;
}

#burgundy {
  color: rgb(153, 40, 59);
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;
}
input:checked ~ label {
  color: rgb(153, 40, 59) !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
}
.form-group input {
  display: none;
}
label {
  padding-left: 10px;
  cursor: pointer;
  margin: 0 !important;
}

.form-group:last-child {
  margin-bottom: 0;
}

/* for the responsive layout of the sidebar*/
.hamburger2 {
  display: none;
  z-index: 10000;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 70px;
  left: 15px;
}
.hamburger2:hover {
  color: #40bff8;
}
.checker {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .shop-header {
    position: fixed;
    top: 124px;
    overflow: auto;
    z-index: 2;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 12px 12px 0px rgba(0, 0, 0, 0.22);
    height: 100%;
  }
  .hamburger2 {
    display: block;
  }
  .checker:not(:checked) ~ .shop-header {
    transform: translateX(-350px);
  }
  .checker:checked ~ .shop-header {
    transform: translateX(0px);
  }
}

.wrapper {
  padding-top: 130px;
}
.shop-container {
  display: flex;
  grid-template-columns: 30rem auto;
  width: 100%;
}

.sidebar {
  width: 100%;
  margin: 0;
}

.load-page {
    background: white;
    overflow: hidden;
}

.loader {
    width: 300px;
    height: 300px;
    margin: auto;
}

.loader div {
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    border: 2px solid #fff;
    border-top: 2px solid lavender;
    border-radius: 50%;
    animation: rotate 10s linear infinite alternate-reverse;
}

@keyframes rotate {
    50%{transform: rotate(80deg);}
    100%{transform: rotate(360deg);}
}
.create_article {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.mb {
  margin-bottom: 10px;
}
.mauto {
  margin: 0 auto;
}

.maxwidth {
  width: 100vw;
}

.upload {
  /* max-width: 450px; */
  height: 250px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
  /* margin: 20px; */
  position: relative;
}

#file_up {
  position: relative;
  height: 100%;
  width: 100%;
  outline: none;
}

#file_up::before {
  content: "+";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -20%;
  left: 0;
  background-color: white;
  color: rgb(250, 200, 107);
  font-size: 17rem;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

#file_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}

#file_img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

#file_img span {
  position: absolute;
  top: -13px;
  right: 13px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 900;
  color: crimson;
}

.textarea,
.preview,
.markdown {
  min-height: 400px;
  border: 1px solid lightgray;
  min-width: 400px;
  padding: 20px;
}

/* CONTEXTUAL HELP */

.qs {
  background-color: #02bdda;
  border-radius: 16px;
  color: #e3fbff;
  cursor: default;
  display: inline-block;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  position: relative;
  text-align: center;
  width: 20px;
}

.qs .popover {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  top: -7rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #fff;
  display: none;
  font-size: 12px;
  font-family: 'Helvetica', sans-serif;
  left: -105px;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 4;
}

.qs .popover:before {
  border-top: 7px solid rgba(0, 0, 0, 0.85);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  content: '';
  display: block;
  left: 50%;
  margin-left: 7px;
  position: absolute;
}

.qs:hover .popover {
  display: block;
  -webkit-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -moz-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -ms-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
}

/* OPEN API */

.grid-main {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 40px;
}

.grid-child {
  border: 2px solid whitesmoke;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.grid-child:hover {
  background-color: whitesmoke;
  color: #212121;
}

.grid-child h3 {
  font-family: Poppins;
  font-weight: 700;
}

.grid-child p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
}

.text-area {
  font-size: 20px;
  padding: 10px;
  font-family: Poppins;
}

.action-btn {
  width: 200px;
  height: 50px;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.result-text {
  font-family: Poppins;
  font-size: 25px;
  line-height: 2.5rem;
}
#error401 {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof {
    display: table-cell;
    vertical-align: middle;
}

.fof h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
    font-family: 'Lato', sans-serif;
    color: #888
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }

    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}
/* Required for react-sticky-state */
.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.sticky.sticky-fixed.is-sticky {
  margin-top: 0;
  margin-bottom: 0;
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sticky.sticky-fixed.is-sticky:not([style*="margin-top"]) {
  margin-top: 0 !important;
}

.sticky.sticky-fixed.is-sticky:not([style*="margin-bottom"]) {
  margin-bottom: 0 !important;
}

.sticky.sticky-fixed.is-absolute {
  position: absolute;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0%;
}

.is-sticky {
  background-color: white;
}

/* Required for react-sticky-state */

.project-group {
  background-color: #1a1e23;
  overflow: hidden;
}

audio,
embed,
iframe,
img,
input,
object,
picture,
video {
  max-width: 100%;
  margin: 0;
}
img {
  display: inline-block;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
img[src*=".svg"] {
  width: 100%\9;
}
a:hover img {
  border: none;
  background: none;
}

img[src*=".svg"],
x::-ms-reveal {
  width: 100%;
}
a img {
  border: none;
}

.case-study-single-button,
button {
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  padding: 0;
  border: 0;
  touch-action: manipulation;
  white-space: nowrap;
}
.case-study-single-button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

button:active,
button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.case-study-single-button {
  font-family: League Spartan, Helvetica, Arial, sans-serif;
  font-size: 0.875em;
  letter-spacing: 3px;
  padding: 12px 20px;
  background-color: #d0a93a;
  background: linear-gradient(270deg, #f06449, #ef3636);
  color: #fff;
  position: relative;
  transition: all 0.3s;
}

.case-study-single-button .button-text {
  position: relative;
  z-index: 10;
  font-size: 2rem;
}

.case-study-single-button .button-mask {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.case-study-single-button .button-mask:after,
.case-study-single-button .button-mask:before {
  will-change: transform;
}
.case-study-single-button .button-mask:before {
  width: 100%;
  background-color: #1b2e63;
  background: linear-gradient(270deg, #206a5d, #0f332c);
  transition: transform 0.6s cubic-bezier(0.694, 0.048, 0.335, 1) 0.05s;
  z-index: 1;
}
.case-study-single-button .button-mask:after,
.case-study-single-button .button-mask:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-110%);
}
.case-study-single-button .button-mask:after {
  width: 80%;
  background-color: #26408b;
  background: linear-gradient(270deg, #206a5d, #0f332c);
  transition: transform 0.6s cubic-bezier(0.694, 0.048, 0.335, 1);
  z-index: 2;
}
.case-study-single-button .button-icon {
  position: absolute;
  top: 3px;
  right: -25px;
  width: 35px;
  height: 35px;
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.case-study-single-button .button-icon svg {
  width: 35px;
  height: 35px;
}
.case-study-single-button .button-icon svg path {
  fill: #484b5f;
}
.case-study-single-button.call-to-button .button-icon svg path {
  fill: #fff;
}
.case-study-single-button:hover .button-mask:before,
.case-study-single-button:hover .button-mask:after {
  transition: transform 0.75s cubic-bezier(0.694, 0.048, 0.335, 1) 0.05s;
}
.case-study-single-button:hover .button-mask:after,
.case-study-single-button:hover .button-mask:before {
  transform: translateX(200%);
}
.case-study-single-button:hover .button-icon {
  transform: translateX(7px);
}
.case-study-single-button.ghost {
  background-color: transparent;
  background: transparent;
  border: 2px solid hsla(0, 0%, 100%, 0.6);
  color: #fff;
  transition: all 0.3s;
}
.case-study-single-button.ghost .button-icon svg path {
  fill: #fff;
}
.case-study-single-button.ghost:hover {
  background-color: #fff;
  color: #1c1d25;
  text-decoration: none;
}
.button-big {
  font-size: 2em;
  padding: 15px 33px;
}
.overview-section {
  padding-top: 4%;
}
.hero-single-work,
.main-hero {
  width: 100%;
  display: block;
  position: relative;
  background-color: #1c1d25;
  z-index: 2;
}

.hero-single-work {
  height: 70vh;
  max-height: 950px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-single-work:before {
  position: absolute;
  left: 0;
  top: 0;
  height: -webkit-fill-available ;
  width: 100%;
  display: block;
  content: " ";
  background: #000;
  opacity: 0.75;
  transition: opacity 0.3s ease;
  z-index: 2;
}
.hero-single-work:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30%;
  background-size: 100%;
  /* background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent); */
}
.hero-single-work-content {
  position: absolute;
  transform-style: preserve-3d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 60%;
  max-width: 700px;
  margin: 0 auto;
  color: #fff;
  z-index: 4;
}
.typography-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10% 0 0 0;
}

.carousel__list {
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 75vw;
  margin: 0 auto;
  max-width: 50rem;
}

.carousel__item {
  flex: 0 0 auto;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 33.75em) {
  .hero-single-work-content {
    width: 90%;
  }
}
.hero-single-work-title {
  font-family: League Spartan, Helvetica, Arial, sans-serif;
  margin-bottom: 13px;
  font-size: 5rem;
}
.hero-single-work-subtitle {
  font-family: LibreBaskerville-Regular, Palatino Linotype, Times New Roman,
    serif;
  font-size: 2.5em;
  font-style: italic;
  font-weight: 400;
}
.hero-single-context-stripe {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 45px;
  color: #fff;
  z-index: 3;
}
.hero-single-context-stripe .context-stripe-focus-area {
  margin: 0;
  padding: 2%;
  list-style: none;
}
.hero-single-context-stripe .context-stripe-focus-area li {
  float: left;
  clear: none;
  width: 31.3333333333%;
  margin-left: 0;
  margin-right: 3%;
  display: flex;
  vertical-align: middle;
  font-family: League Spartan, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1.3em;
  letter-spacing: 2px;
}
.hero-single-context-stripe .context-stripe-focus-area li:last-child {
  margin-right: 0;
}
.hero-single-context-stripe .context-stripe-focus-area li:nth-child(2) {
  text-align: center;
}
.hero-single-context-stripe .context-stripe-focus-area li:last-child {
  text-align: right;
}
.project-group
  .hero-single-context-stripe
  .context-stripe-focus-area
  li
  strong {
  color: #d0a93a;
}

.context-stripe-focus-area li {
  display: flex;
  align-items: baseline;
}

@media only screen and (max-width: 56.25em) {
  .hero-single-context-stripe .context-stripe-focus-area {
    text-align: center;
    display: flex;
  }

  .single-work-giga-text {
    left: 20% !important;
  }

  .single-work-anim-text {
    flex-direction: column;
  }

  .case-study-single-button .button-icon svg {
    display: none;
  }

  .typography-container {
    flex-direction: column;
  }

  .single-work-text-content.is-left {
    padding: 0% !important;
    margin-left: 0% !important;
  }

  .hero-single-context-stripe .context-stripe-focus-area li {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    display: inline-block;
    text-align: center;
    margin-right: 20px;
  }
  .hero-single-context-stripe .context-stripe-focus-area li:first-child {
    margin-left: auto;
  }
  .hero-single-context-stripe .context-stripe-focus-area li:last-child {
    margin-right: auto;
  }
  .hero-single-context-stripe .context-stripe-focus-area li:nth-child(2) {
    text-align: center;
  }
  .hero-single-context-stripe .context-stripe-focus-area li:last-child {
    text-align: center;
    margin-right: 0;
  }
}
@media only screen and (max-width: 33.75em) {
  .hero-single-context-stripe .context-stripe-focus-area li {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .hero-single-context-stripe .context-stripe-focus-area,
  .outline-title {
    display: none;
  }
  .overview-container.project-services {
    grid-template-columns: 1fr !important;
  }
  .service-item {
    grid-template-columns: 1fr !important;
    grid-auto-columns: 1fr !important;
    grid-template-areas: none !important;
  }
  .button-content {
    padding: 0% !important;
  }
  .single-work-giga-text,
  .typography-container {
    display: none !important;
  }
  .inner-container {
    margin-left: 0% !important;
  }
  .sticky-labels.services-label,
  .sticky-labels {
    margin-top: 0% !important;
    margin-bottom: 0% !important;
  }
}

.case-studies-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.case-studies-list .case-study {
  display: block;
  width: 90%;
  opacity: 1;
  height: 480px;
  background-color: #1c1d25;
  position: relative;
  /* box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.45); */
  margin-left: auto;
  margin-right: auto;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.35s ease;
}
.case-studies-list .case-study:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: block;
  content: " ";
  background: #1c1d25;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  z-index: 2;
}
.case-studies-list .case-study .case-study-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.case-studies-list .case-study .case-study-mask:after {
  content: "";
  position: absolute;
  top: 0;
  opacity: 1;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(28, 29, 37, 0.9); */
  background: linear-gradient(
    270deg,
    rgb(32, 106, 93, 0.9),
    rgb(11, 39, 34, 0.9)
  );
  transform: translateX(-100%);
  transition: transform 0.35s cubic-bezier(0.694, 0.048, 0.335, 1) 0.2s,
    opacity 0.2s;
  z-index: 9;
}
.case-studies-list .case-study .case-study-reveal-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
}
.case-studies-list .case-study .case-study-reveal-mask:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  background: linear-gradient(270deg, #206a5d, #0f332c);
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.694, 0.048, 0.335, 1) 0.15s;
  z-index: 101;
}
.case-studies-list .case-study .case-study-mask-number {
  display: none;
}

.case-studies-list li:nth-child(even) .case-study .case-study-mask-number {
  right: auto;
  left: -45px;
}
.case-studies-list li:last-child .case-study {
  margin-bottom: 0;
}
@media only screen and (min-width: 64em) {
  .case-studies-list li:nth-child(even) .case-study {
    transform: translateX(-25px);
  }
  .case-studies-list li:nth-child(odd) .case-study {
    transform: translateX(25px);
  }
  .case-studies-list .case-study .case-study-mask-number {
    display: block;
    position: absolute;
    top: -40px;
    right: -45px;
    width: 265px;
    height: 198px;
    font-size: 11.5625em;
    overflow: hidden;
    font-family: League Spartan, Helvetica, Arial, sans-serif;
    color: #99999d;
    transform: translateY(15px);
    opacity: 0;
    transition: opacity 0.3s,
      transform 0.45s cubic-bezier(0.694, 0.048, 0.335, 1);
    z-index: 90;
  }
  .case-studies-list .case-study .case-study-mask-back,
  .case-studies-list .case-study .case-study-mask-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .case-studies-list .case-study:hover {
    transform: scale(0.99);
    /* box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.65); */
  }
  .case-studies-list .case-study:hover .case-study-mask:after {
    transform: translateX(0);
  }
  .case-studies-list .case-study:hover .case-study-mask-number {
    opacity: 1;
    transform: translateY(0);
  }

  .case-studies-list .case-study.is-hidden .case-study-reveal-mask:after {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 56.25em) {
  .case-studies-list .case-study {
    width: 90%;
    height: 420px;
  }
}
@media only screen and (max-width: 41.25em) {
  .case-studies-list .case-study {
    height: 340px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 25em) {
  .case-studies-list .case-study {
    width: 95%;
    height: 290px;
  }
  .case-studies-list .case-study .case-study-title {
    font-size: 1.125em;
  }
}

section {
  width: 100%;
  display: block;
  position: relative;
}
section:after,
section:before {
  content: " ";
  display: table;
}
section:after {
  clear: both;
}
.project-content {
  margin: 0 auto;
  /* color: #fff; */
  padding: 5% 0 0 2%;
}
.ui-components {
  padding: 0% !important;
}
.analysis-section {
  padding-bottom: 0% !important;
}
.project-content p {
  font-size: 2rem;
}
.project-content:after,
.project-content:before {
  content: " ";
  display: table;
}
.project-content:after {
  clear: both;
}
@media only screen and (min-width: 75em) {
  .project-content {
    width: 100%;
  }
}
@media only screen and (max-width: 64em) {
  .project-content {
    width: 95%;
  }
}
.inner-container {
  width: 100%;
  position: relative;
}
.inner-container:after,
.inner-container:before {
  content: " ";
  display: table;
}
.inner-container:after {
  clear: both;
}

.button-content {
  padding: 0.3rem 45rem;
  padding-bottom: 5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.hidden,
.visual-hide {
  overflow: hidden;
}
.visual-hide {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  position: absolute;
  width: 1px;
}

.section-title {
  text-align: center;
  margin-bottom: 70px;
  color: dimgray;
  transition-delay: 0.18s;
  font-size: 2.5rem;
}
.section-subtitle {
  text-align: center;
  font-family: LibreBaskerville-Regular, Palatino Linotype, Times New Roman,
    serif;
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 2px;
  color: #d0a93a;
  margin-bottom: 25px;
  transition-delay: 0.3s;
}
.section-subtitle,
.section-title {
  opacity: 1;
  transition: transform 0.45s cubic-bezier(0.694, 0.048, 0.335, 1),
    opacity 0.32s;
}
.section-text-mask {
  display: block;
  overflow: hidden;
}
@media only screen and (min-width: 64.0625em) {
  .section-text-mask.is-hidden .section-subtitle,
  .section-text-mask.is-hidden .section-title {
    transform: translateY(-120%);
    opacity: 0;
  }
}

.single-work-section {
  padding-top: 80px 0;
  color: #000;
}
.single-work-intro-section {
  text-align: center;
  overflow: hidden;
}
.single-work-giga-text {
  font-size: 11.875em;
  position: absolute;
  bottom: -140px;
  left: 6%;
  opacity: 0.1;
  font-family: League Spartan, Helvetica, Arial, sans-serif;
  z-index: 0;
}
.single-work-giga-text svg {
  width: 700px;
  height: 200px;
}
.single-work-giga-text svg path {
  fill: #fff;
}
@media only screen and (min-width: 103.125em) {
  .single-work-giga-text {
    left: 25%;
    transform: scale(1.5);
  }
}
.single-work-intro-title {
  display: inline-block;
  font-size: 3.75em;
  margin-bottom: 20px;
  /* color: #fff; */
  overflow: hidden;
  position: relative;
  line-height: 1.3;
  z-index: 1;
}
.single-work-intro-title span {
  display: block;
  position: relative;
}
.single-work-intro-title span:before {
  content: attr(data-letters);
  position: absolute;
  /* color: #fff; */
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.694, 0.048, 0.335, 1) 0.2s;
}
.single-work-intro-title:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  background: #fff;
  transform: translate3d(101%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.single-work-intro-title.is-hidden:after {
  transform: translateZ(0);
}
.single-work-intro-title.is-hidden span:before {
  width: 0;
}
@media only screen and (max-width: 64em) {
  .single-work-intro-title:after,
  .single-work-intro-title span:before {
    display: none;
  }
}
.single-work-anim-text {
  transition: all 0.3s;
}
.single-work-anim-text.is-hidden {
  transform: translateY(30px);
  opacity: 0;
}
@media only screen and (max-width: 64em) {
  .single-work-anim-text {
    transform: none;
    opacity: 1;
  }
}
.single-work-first-anim-blocks {
  transition: all 0.3s;
}
.single-work-first-anim-blocks.is-hidden {
  transform: translateY(30px);
  opacity: 0;
}
.single-work-first-anim-blocks.is-hidden:last-child {
  transition-delay: 0.15s;
}
@media only screen and (max-width: 64em) {
  .single-work-first-anim-blocks {
    transform: none;
    opacity: 1;
  }
}
.single-work-intro-text {
  width: 48%;
  margin: 0 auto;
  /* color: #fff; */
  position: relative;
  z-index: 1;
}
.single-work-text-content {
  float: left;
  clear: none;
  width: 38.2%;
  margin-left: 0;
  margin-right: 3%;
  color: #000;
}
.single-work-text-content:last-child {
  margin-right: 0;
}
.single-work-text-content.is-left {
  padding-left: 50px;
}
.single-work-content-title {
  font-size: 3rem;
  text-transform: capitalize;
}
.single-work-content-separator {
  width: 27%;
  height: 4px;
  background-color: #235aa6;
  margin: 26px 0;
}
.single-work-content-desc a .single-work-content-desc a {
  text-decoration: underline;
}
/* .single-work-content-desc p {
  text-align:  left;
  white-space: nowrap;
  overflow: hidden;
  width: 40em;
  animation: type 3s steps(60, end); 
}
@keyframes type{
  0%{width: 0;}
  50%{width: 0;}
  100%{ width: 100; } 
} */
.single-work-img-content {
  float: left;
  clear: none;
  width: 58.8%;
  margin-left: 0;
  margin-right: 3%;
}
.single-work-img-content:last-child {
  margin-right: 0;
}
.single-work-img-content img {
  display: block;
  width: 80%;
  margin-left: 10%;
  transition: transform 0.3s;
  border-radius: 12px;
}
.single-work-img-content img:hover {
  transform: scale(1.01);
}
.color-palette-section {
  padding-top: 85px;
}
.color-palette-section .color-palette-container {
  float: left;
  clear: none;
  width: 5%;
  margin-left: 9%;
  margin-right: 3%;
  text-align: center;
}
.color-palette-section .color-palette-container:last-child {
  margin-right: 0;
}
.color-palette-section .color-palette-container .color-palette {
  display: block;
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
  background-color: #fff;
  /* box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.33); */
  border-radius: 50%;
  transition: all 0.3s;
}
.color-palette-section .color-palette-container .color-palette-name {
  display: block;
  padding-top: 25px;
  font-size: 1em;
  font-family: Consolas, Monaco, Bitstream Vera Sans Mono, Courier, monospace;
  font-weight: 400;
  color: dimgray;
  transition: color 0.3s;
}
.color-palette-section .color-palette-container.is-hidden .color-palette {
  transform: translateY(30px);
  opacity: 0;
}
@media only screen and (max-width: 64em) {
  .color-palette-section .color-palette-container .color-palette {
    transform: none;
    opacity: 1;
  }
}
.color-palette-section .color-palette-container:hover .color-palette {
  /* box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.23); */
}
.color-palette-section .color-palette-container:hover .color-palette-name {
  color: #d0a93a;
}
.single-work-font {
  float: left;
  clear: none;
  width: 50%;
  margin-left: 0;
  margin-right: 0;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 40px;
  height: 400px;
}
.single-work-ui .section-title {
  margin-bottom: 25px;
}
.single-work-ui-row {
  padding: 20px 0 40px;
}

.next-work {
  display: block;
  width: 100%;
  padding: 45px;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #eee;
}
.next-work .content {
  position: relative;
  z-index: 20;
}
.next-work:after,
.next-work:before {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  left: 0;
  height: 100%;
  transition: transform 0.38s cubic-bezier(0.694, 0.048, 0.335, 1);
  /* -webkit-transform: translateY(-100%); */
  /* transform: translateY(-100%); */
}
.next-work:before {
  /* background-color: #d0a93a; */
  background-color: rgb(0, 0, 0, 0.25) !important;
  opacity: 0.5 !important;
  transition-delay: 0.12s;
  z-index: 2;
}

.next-work:after {
  background-color: #d0a93a;
  z-index: 1;
}

.next-work .next-work-lead {
  font-family: LibreBaskerville-Regular, Palatino Linotype, Times New Roman,
    serif;
  text-transform: uppercase;
  color: #d0a93a;
  font-size: 1.75em;
  font-weight: 400;
  margin-bottom: 25px;
}
.pervious-work-lead {
  text-align: end;
}
.next-work .next-work-title {
  display: inline-block;
  position: relative;
}
.next-work-title {
  color: #d0a93a;
  font-size: 3.5rem;
}
.pervious-work-title {
  width: inherit;
  text-align: end;
}
.next-work .next-work-title:after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f06449;
  z-index: -1;
}
.next-work .next-work-arrow {
  position: absolute;
  transform-style: preserve-3d;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
  top: 65%;
  width: 150px;
  height: 70px;
  opacity: 0.7;
  transition: transform 0.25s cubic-bezier(0.694, 0.048, 0.335, 1);
  z-index: 2;
}
.pervious-work-arrow {
  transform: rotateY(180deg) !important;
  left: 0 !important;
  top: 50% !important;
}
.pervious-work-arrow svg path {
  fill: #fff !important;
}
.next-work .next-work-arrow svg {
  width: 150px;
  height: 70px;
}
.next-work .next-work-arrow svg path {
  fill: #1c1d25;
  transition: all 0.3s ease;
}
.next-work:focus .next-work-lead,
.next-work:focus .next-work-title,
.next-work:hover .next-work-lead,
.next-work:hover .next-work-title {
  color: #fff;
  z-index: 2;
}
.next-work:focus:after,
.next-work:focus:before,
.next-work:hover:after,
.next-work:hover:before {
  transform: translateY(0);
}
.next-work:focus .next-work-arrow,
.next-work:hover .next-work-arrow {
  transition-delay: 0.3s;
  transform: translate(20%, -50%);
}
.next-work:focus .next-work-arrow svg path,
.next-work:hover .next-work-arrow svg path {
  fill: #fff;
}

.project-group .single-work-content-separator {
  background-color: #ebb741;
}
.project-group .single-work-content-desc a,
.project-group .single-work-content-desc span,
.project-group .single-work-content-desc strong {
  /* color: #ebb741; */
}
@media only screen and (min-width: 64em) {
  .project-group .single-work-intro-title {
    color: #ebb741;
  }
}

.project-group .next-work:before {
  background-color: #d0a93a;
}
.project-group .pervious-work:before {
  background-color: #000 !important;
}
.project-group .next-work:after {
  background-color: #060c10;
  background-color: rgb(0, 0, 0, 0.25) !important;
  opacity: 0.5 !important;
}
.project-group .next-work-title:after {
  background-color: #d0a93a;
  color: #fff;
}

@media only screen and (max-width: 59.375em) {
  .single-work-section {
    padding: 50px 0;
  }
  .single-work-intro-text {
    width: 85%;
    font-size: 0.875em;
  }
  .color-palette-section .color-palette-container .color-palette {
    width: 100px;
    height: 100px;
  }
  .color-palette-section .color-palette-container .color-palette-name {
    font-size: 0.75em;
  }
  .next-work {
    padding: 22px 0;
  }
  .next-work-lead,
  .next-work-title {
    margin-left: 22px;
  }
}
@media only screen and (max-width: 56.25em) {
  .single-work-text-content {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
  }
  .single-work-text-content:first-child {
    margin-left: auto;
  }
  .single-work-text-content:last-child {
    margin-right: auto;
  }
  .single-work-text-content.is-left {
    padding-left: 0;
    padding: 0 8%;
  }
  .single-work-img-content {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .single-work-img-content:first-child {
    margin-left: auto;
  }
  .single-work-img-content:last-child {
    margin-right: auto;
  }
  .single-work-img-content img {
    display: block;
    width: 90%;
    margin: 0 auto;
    transition: none;
    border-radius: 12px;
  }
  .single-work-content-desc {
    font-size: 0.9em;
  }
  .single-work-content-separator {
    display: none;
  }
  .single-work-font {
    height: 250px;
  }
}
@media only screen and (max-width: 37.5em) {
  .single-work-intro-title {
    font-size: 1.875em;
  }
  .color-palette-section .color-palette-container {
    float: left;
    clear: none;
    width: 48.5%;
    margin-left: 0;
    margin-right: 3%;
    margin-bottom: 20px;
  }
  .color-palette-section .color-palette-container:nth-of-type(2n) {
    margin-right: 0;
    float: right;
  }
  .color-palette-section .color-palette-container:nth-of-type(2n + 1) {
    clear: both;
  }
  .color-palette-section .color-palette-container:last-child {
    width: 100%;
  }
  .color-palette-section .color-palette-container .color-palette {
    width: 160px;
    height: 160px;
  }
  .next-work .next-work-arrow,
  .single-work-font {
    display: none;
  }
}
@media only screen and (max-width: 33.75em) {
  .single-work-ui {
    display: none;
  }
}
@media only screen and (max-width: 29.375em) {
  .color-palette-section .color-palette-container {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .color-palette-section .color-palette-container:first-child {
    margin-left: auto;
  }
  .color-palette-section .color-palette-container:last-child {
    margin-right: auto;
  }
}

.single-work-giga-text {
  font-size: 5.875em;
  color: white;
  position: absolute;
  bottom: -12rem;
  left: 56%;
  opacity: 0.1;
  font-family: "Abril Fatface", cursive;
  z-index: 0;
}
.single-work-giga-text p {
  width: 700px;
  height: 200px;
}

.main-case-study-text {
  width: 65%;
  margin: 0 auto;
  line-height: 1.5;
  font-size: 1.2rem;
}

.case-study-flows {
  width: 65%;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .service-item {
    padding-right: 50px;
    grid-column-gap: 50px;
    grid-template-areas: ". Area";
    grid-template-columns: 2fr;
  }
}

.service-item {
  display: grid;
  margin-bottom: 50px;
  flex-direction: column;
  grid-auto-columns: 2fr;
  grid-column-gap: 50px;
  grid-row-gap: 16px;
  grid-template-areas: ". Area";
  grid-template-columns: 2fr;
  grid-template-rows: auto;
}

@media screen and (min-width: 1280px) {
  .overview-titles {
    margin-bottom: 30px;
  }
}

.overview-titles {
  margin-top: 0px;
  margin-bottom: 25px;
  font-family: Quark, sans-serif;
  font-size: 1.6em;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: left;
  /* color: #fff; */
}

.separation-line.service-line {
  width: 50px;
  margin-bottom: 15px;
}

.separation-line {
  width: 100px;
  height: 2px;
  margin-bottom: 50px;
  background-color: #fff;
}

.overview-descrp {
  margin-bottom: 30px;
  padding-top: 0px;
  font-family: Lato, sans-serif;
  font-size: 1em;
  line-height: 1.6em;
  font-weight: 300;
  /* color: #fff; */
  text-align: left;
}

.overview-container.project-services {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
}

.overview-container {
  position: relative;
  width: 95%;
  /* max-width: 1450px; */
}

.services-label {
  align-self: start;
  justify-self: center;
  grid-column-start: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

.sticky-labels.services-label {
  margin-top: 15px;
  margin-bottom: 200px;
  margin-left: 0px;
}
.sticky-labels {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  display: flex;
  width: 100px;
  margin-top: 0px;
  margin-bottom: 500px;
  margin-left: -30px;
  transform: rotate(90deg);
}

.outline-title {
  flex: 0 0 auto;
  font-family: Quark, sans-serif;
  /* color: #d9d9d9; */
  font-size: 4.4em;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 30px;
  line-height: 0.9em;
  font-weight: 700;
  opacity: 0.6;
}

.overview-descrp {
  width: 85%;
}
.app {
  margin-top: 2rem;
  position: relative;
  /* animation: leftIn 3s ease-in -1s normal; */
}
@keyframes leftIn {
    0% {
      right: 250%;
    }
    100% {
      right: 0%;
    }
  }


.button {
  text-decoration: none;
  color: whitesmoke;
  padding: 1em 2em;
  background-color: #afbac3;
  border: 0.16em solid white;
  border-radius: 5em;
  font-size: 1.5rem;
}
.button i {
  margin: 0 0.75em 0 0;
}
.highlight {
  background-color: white;
  color: #afbac3;
}
.button:hover {
  background-color: transparent;
  text-decoration: none;
  color: #afbac3;
  box-shadow: 0 16px 30px -20px black;
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.3s ease;
}

#overlay-wrapper {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;
}

#overlay-wrapper a {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.25
  ); 
  /* Adjust the last value (0.5) for the desired transparency */
  pointer-events: none; /* Allows clicking through the overlay to the image */
}

body:not(.no-js) .image-wrap {
  transition: 3s ease-out;
  transition-delay: 0.2s;
  position: relative;
  width: auto;
  overflow: hidden;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
          clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
}

body:not(.no-js) .animating .image-wrap {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}


#text-slide {
  z-index: 100;

  &:hover {
    opacity: 0.5;
  }
}

#text-slide {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  padding-bottom: 4vmin;
  height: 90vh;
  width: 100%;
  background: #ede8e2;
  color: #3a3535;
}

@keyframes text-clip {
  from {
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}

@keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}

.cafe,
.mozart {
  animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.cafe-inner {
  display: inline-block;
  animation: inner-left 1s 1s ease both,
    text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.mozart-inner {
  animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.cafe {
  > .cafe-inner {
    display: inline-block;
  }
}

.mozart {
  display: inline-block;
}




.text-block-inner {
  width: 100%;
  margin: 0 auto;
}

.text-block-group {
  list-style-type: none;
}

.text-block-paragraph + .text-block-paragraph {
  margin-top: 10px;
}

.text-block-group:first-child {
  margin-top: 0;
}

.text-block-group:last-child {
  margin-bottom: 0;
}

.text-block-group:nth-child(even) {
  text-align: right;
}

.text-block-paragraph a {
  color: #fff;
}

.text-block-paragraph a:hover {
  text-decoration: none;
}

[data-js="reveal"] {
}

[data-reveal="content"] {
  display: inline-block;
  position: relative;
}

[data-reveal="cover"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 1;
}

[data-reveal="text"] {
  opacity: 0;
}

@keyframes reveal-cover {
    0%   { width: 0;left: 0; }
    44%  { width: 100%;left: 0; }
    54%  { width: 100%;left: 0;  }
    100% { width: 0;left: 100%; }
}

@keyframes reveal-text {
    0%   { opacity: 0; }
    44%  { opacity: 0; }
    54%  { opacity: 1; }
}

[data-js="reveal"].loaded [data-reveal="cover"] {
  animation: reveal-cover 1.5s ease-in-out;
}

[data-js="reveal"].loaded [data-reveal="text"] {
  opacity: 1;
  animation: reveal-text 1.5s ease-in-out;
}


.paragraph-fade {
  position: relative;
  display: none;
}
.animateText {
  display: block;
  color: black !important;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.paragraph-fade span {
  display: inline-block;
  color: #000 !important;
  opacity: 0;
  filter: blur(4px);
}

.paragraph-fade span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.paragraph-fade span:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.login-row { 
  display: block;
}

.form-group {
  width: -webkit-fill-available !important;
}

.main-content {
  width: 50%;
  border-radius: 20px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .4);
  margin: 5em auto;
  display: flex;
}

.company__info {
  background-color: #008080;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.fa-android {
  font-size: 3em;
}

#password {
  padding-left: 25px;
  /* <img src="https://img.icons8.com/material-sharp/24/null/visible.png" /> */
  /* 
  */
  background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat right;
  background: url("https://img.icons8.com/material-sharp/24/null/visible.png") no-repeat right; 
  background-size: 20px;
}

.password-visible {
  background: url("https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/null/external-eye-devices-flatart-icons-outline-flatarticons.png") no-repeat right; 
}

@media screen and (max-width: 640px) {
  .main-content {
    width: 90%;
  }

  .company__info {
    display: none;
  }

  .login_form {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

@media screen and (min-width: 642px) and (max-width:800px) {
  .main-content {
    width: 70%;
  }
}

.row>h2 {
  color: #008080;
}

.login_form {
  background-color: #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

form {
  padding: 0 2em;
}

.form__input {
  width: 100%;
  border: 0px solid transparent;
  border-radius: 0;
  border-bottom: 1px solid #aaa;
  padding: 1em .5em .5em;
  padding-left: 2em;
  outline: none;
  margin: 1.5em auto;
  transition: all .5s ease;
}

.form__input:focus {
  border-bottom-color: #008080;
  box-shadow: 0 0 5px rgba(0, 80, 80, .4);
  border-radius: 4px;
}

.login-btn {
  transition: all .5s ease;
  width: 70%;
  border-radius: 30px;
  color: #008080;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid #008080;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.login-btn:hover,
.login-btn:focus {
  background-color: #008080;
  color: #fff;
}
.header-nav {
  background-color: #1a1e23;
}

.nav-logo {
  width: 10rem;
}

.nav-link {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  color: white !important;
  font-size: 2.5rem;
  margin: 2rem;
}

.dropdown-menu {
  background-color: #1a1e23 !important;
}

.nav-link:focus,
.nav-link:hover,
.nav-link:active {
  padding: 5px 7px;
  border-color: rgba(255, 255, 255, 1);
  outline: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  transition: 0.5s color ease;
  color: #81b3d2;
}

.nav-combo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
}

.left-nav {
  font-weight: 700;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Lato", sans-serif;
  list-style-type: none;
}

.dropdown-menu {
  overflow-x: hidden;
}

.burger-nav {
  display: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
  color: rgb(235, 183, 65) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #206a5d !important;
  background-color: transparent;
}

/* Small devices (portrait tablets and large phones, 480px and up) */

@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .burger-nav {
    display: flex;
    width: 100%;
    height: 8vh;
    background-color: #1a1e23;
  }
  .nav-logo,
  .nav-title {
    display: none;
  }
  .nav-link {
    text-align: left;
    width: 100%;
    margin: 0;
    color: white;
    padding: 10px;
    border-bottom: 1px solid #404040;
  }
  .left-nav {
    overflow: hidden;
    display: block;
    height: 0;
  }
  .left-nav.open {
    height: auto;
  }
  .nav-combo {
    justify-content: start;
  }
}

.col_white_amrc {
  color: #fff;
}

footer {
  width: 100%;
  background-color: #1a1e23;
  min-height: 250px;
  padding: 10px 0px 25px 0px;
}

.pt2 {
  padding-top: 40px;
  margin-bottom: 20px;
}

footer p {
  font-size: 1.8em;
  color: #ccc;
  padding-bottom: 0px;
  margin-bottom: 8px;
  display: flex;
  grid-gap: 4%;
  gap: 4%;
  align-items: center;
}

.mb10 {
  padding-bottom: 15px;
}

.footer_h5 {
  font-size: 2.5rem;
}

.footer_ul_amrc {
  margin: 0px;
  list-style-type: none;
  font-size: 1.5em;
  padding: 0px 0px 10px 0px;
}

.footer_ul_amrc li {
  padding: 0px 0px 5px 0px;
}

.footer_ul_amrc li a {
  color: #ccc;
}

.footer_ul_amrc li a:hover {
  color: #fff;
  text-decoration: none;
}

.copyright {
  display: flex;
  justify-content: center;
}

.fleft {
  float: left;
}

.padding-right {
  padding-right: 10px;
}

.footer_ul2_amrc {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}

.footer_ul2_amrc li {
  display: flex;
  align-items: baseline;
}

.footer_ul2_amrc li p {
  display: table;
}

.footer_ul2_amrc li a:hover {
  text-decoration: none;
}

.footer_ul2_amrc li i {
  margin-top: 5px;
}

.bottom_border {
  border-bottom: 1px solid #323f45;
  padding-bottom: 20px;
  width: 100%;
}

.social_footer_ul li a {
  display: flex;
}

.foote_bottom_ul_amrc {
  list-style-type: none;
  padding: 0px;
  display: table;
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}
.foote_bottom_ul_amrc li {
  display: inline;
  font-size: 1.5rem;
}

.foote_bottom_ul_amrc li a {
  color: #999;
  margin: 0 12px;
}

.dompl {
  margin-left: 50px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .twitter-footer {
    display: none;
  }

  .social_footer_ul {
    margin-left: 10% !important;
  }
}

@media only screen and (max-width: 480px) {
  .dompl {
    margin: 0%;
  }
}

.social_footer_ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 25%;
  margin-right: 25%;
}

.social_footer_ul li {
  padding-left: 1.5rem;
  padding-top: 3rem;
  float: left;
  margin: 0 auto;
  list-style: none;
}

.social_footer_ul li a {
  font-size: 1.5rem;
  color: #ccc;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 50%;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .social_footer_ul li i {
    font-size: 2rem;
  }
  .social_footer_ul li {
    padding-left: 16px;
  }
  .social_footer_ul {
    padding: 0;
  }
}

.card-box {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e7eaed;
    padding: 1.5rem;
    margin-bottom: 24px;
    border-radius: .25rem;
}

.avatar-xl {
    height: 6rem;
    width: 6rem;
}

.rounded-circle {
    border-radius: 50% !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #1abc9c;
}

.nav-pills .nav-link {
    border-radius: .25rem;
}

.navtab-bg li>a {
    background-color: #f7f7f7;
    margin: 0 5px;
}

.nav-pills>li>a,
.nav-tabs>li>a {
    color: #6c757d;
    font-weight: 600;
}

.mb-4,
.my-4 {
    margin-bottom: 2.25rem !important;
}

.tab-content {
    padding: 20px 0 0 0;
}

.progress-sm {
    height: 5px;
}

.m-0 {
    margin: 0 !important;
}

.table .thead-light th {
    color: #6c757d;
    background-color: #f1f5f7;
    border-color: #dee2e6;
}

.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 4px);
    display: block;
    border: 2px solid #adb5bd;
    border-radius: 50%;
    color: #adb5bd;
}

.text-purple {
    color: #6559cc !important;
}

.border-purple {
    border-color: #6559cc !important;
}

.timeline {
    position: relative;
}

.timeline:before {
    background-color: #dee2e6;
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0;
}

.timeline .time-show {
    margin-bottom: 30px;
    margin-top: 30px;
    position: relative;
}

.timeline .timeline-box {
    background: #fff;
    display: block;
    margin: 15px 0;
    position: relative;
    padding: 20px;
}

.timeline .timeline-album {
    margin-top: 12px;
}

.timeline .timeline-album a {
    display: inline-block;
    margin-right: 5px;
}

.timeline .timeline-album img {
    height: 36px;
    width: auto;
    border-radius: 3px;
}

@media (min-width: 768px) {
    .timeline .time-show {
        margin-right: -69px;
        text-align: right;
    }

    .timeline .timeline-box {
        margin-left: 45px;
    }

    .timeline .timeline-icon {
        background: #dee2e6;
        border-radius: 50%;
        display: block;
        height: 20px;
        left: -54px;
        margin-top: -10px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 20px;
    }

    .timeline .timeline-icon i {
        color: #98a6ad;
        font-size: 13px;
        position: absolute;
        left: 4px;
    }

    .timeline .timeline-desk {
        display: table-cell;
        vertical-align: top;
        width: 50%;
    }

    .timeline-item {
        display: table-row;
    }

    .timeline-item:before {
        content: "";
        display: block;
        width: 50%;
    }

    .timeline-item .timeline-desk .arrow {
        border-bottom: 12px solid transparent;
        border-right: 12px solid #fff !important;
        border-top: 12px solid transparent;
        display: block;
        height: 0;
        left: -12px;
        margin-top: -12px;
        position: absolute;
        top: 50%;
        width: 0;
    }

    .timeline-item.timeline-item-left:after {
        content: "";
        display: block;
        width: 50%;
    }

    .timeline-item.timeline-item-left .timeline-desk .arrow-alt {
        border-bottom: 12px solid transparent;
        border-left: 12px solid #fff !important;
        border-top: 12px solid transparent;
        display: block;
        height: 0;
        left: auto;
        margin-top: -12px;
        position: absolute;
        right: -12px;
        top: 50%;
        width: 0;
    }

    .timeline-item.timeline-item-left .timeline-desk .album {
        float: right;
        margin-top: 20px;
    }

    .timeline-item.timeline-item-left .timeline-desk .album a {
        float: right;
        margin-left: 5px;
    }

    .timeline-item.timeline-item-left .timeline-icon {
        left: auto;
        right: -56px;
    }

    .timeline-item.timeline-item-left:before {
        display: none;
    }

    .timeline-item.timeline-item-left .timeline-box {
        margin-right: 45px;
        margin-left: 0;
        text-align: right;
    }
}

@media (max-width: 767.98px) {
    .timeline .time-show {
        text-align: center;
        position: relative;
    }

    .timeline .timeline-icon {
        display: none;
    }
}

.timeline-sm {
    padding-left: 110px;
}

.timeline-sm .timeline-sm-item {
    position: relative;
    padding-bottom: 20px;
    padding-left: 40px;
    border-left: 2px solid #dee2e6;
}

.timeline-sm .timeline-sm-item:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: -7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #1abc9c;
}

.timeline-sm .timeline-sm-item .timeline-sm-date {
    position: absolute;
    left: -104px;
}

@media (max-width: 420px) {
    .timeline-sm {
        padding-left: 0;
    }

    .timeline-sm .timeline-sm-date {
        position: relative !important;
        display: block;
        left: 0 !important;
        margin-bottom: 10px;
    }
}
.profile-info-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.friend-list,
.img-grid-list {
  margin: -1px;
  list-style-type: none;
}
.profile-info-list > li.title {
  font-size: 0.625rem;
  font-weight: 700;
  color: #8a8a8f;
  padding: 0 0 0.3125rem;
}
.profile-info-list > li + li.title {
  padding-top: 1.5625rem;
}
.profile-info-list > li {
  padding: 0.625rem 0;
}
.profile-info-list > li .field {
  font-weight: 700;
}
.profile-info-list > li .value {
  color: #666;
}
.profile-info-list > li.img-list a {
  display: inline-block;
}
.profile-info-list > li.img-list a img {
  max-width: 2.25rem;
  border-radius: 2.5rem;
}
.coming-soon-cover img,
.email-detail-attachment .email-attachment .document-file img,
.email-sender-img img,
.friend-list .friend-img img,
.profile-header-img img {
  max-width: 100%;
}
.table.table-profile th {
  border: none;
  color: #000;
  padding-bottom: 0.3125rem;
  padding-top: 0;
}
.table.table-profile td {
  border-color: #c8c7cc;
}
.table.table-profile tbody + thead > tr > th {
  padding-top: 1.5625rem;
}
.table.table-profile .field {
  color: #666;
  font-weight: 600;
  width: 25%;
  text-align: right;
}
.table.table-profile .value {
  font-weight: 500;
}
.profile-header {
  position: relative;
  overflow: hidden;
}
.profile-header .profile-header-cover {
  background: url(https://bootdey.com/img/Content/bg1.jpg) center no-repeat;
  background-size: 100% auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.profile-header .profile-header-cover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.25) 0,
    rgba(0, 0, 0, 0.85) 100%
  );
}
.profile-header .profile-header-content,
.profile-header .profile-header-tab,
.profile-header-img,
body .fc-icon {
  position: relative;
}
.profile-header .profile-header-tab {
  background: #fff;
  list-style-type: none;
  margin: -1.25rem 0 0;
  padding: 0 0 0 8.75rem;
  border-bottom: 1px solid #c8c7cc;
  white-space: nowrap;
}
.profile-header .profile-header-tab > li {
  display: inline-block;
  margin: 0;
}
.profile-header .profile-header-tab > li > a {
  display: block;
  color: #000;
  line-height: 1.25rem;
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.75rem;
  border: none;
}
.profile-header .profile-header-tab > li.active > a,
.profile-header .profile-header-tab > li > a.active {
  color: #007aff;
}
.profile-header .profile-header-content:after,
.profile-header .profile-header-content:before {
  content: "";
  display: table;
  clear: both;
}
.profile-header .profile-header-content {
  color: #fff;
  padding: 1.25rem;
}
body .fc th a,
body .fc-ltr .fc-basic-view .fc-day-top .fc-day-number,
body .fc-widget-header a {
  color: #000;
}
.profile-header-img {
  float: left;
  width: 7.5rem;
  height: 7.5rem;
  overflow: hidden;
  z-index: 10;
  margin: 0 1.25rem -1.25rem 0;
  padding: 0.1875rem;
  border-radius: 0.25rem;
  background: #fff;
}
.profile-header-info h4 {
  font-weight: 500;
  margin-bottom: 0.3125rem;
}
.profile-container {
  padding: 1.5625rem;
}
@media (max-width: 967px) {
  .profile-header-img {
    width: 5.625rem;
    height: 5.625rem;
    margin: 0;
  }
  .profile-header-info {
    margin-left: 6.5625rem;
    padding-bottom: 0.9375rem;
  }
  .profile-header .profile-header-tab {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .profile-header .profile-header-cover {
    background-position: top;
  }
  .profile-header-img {
    width: 3.75rem;
    height: 3.75rem;
    margin: 0;
  }
  .profile-header-info {
    margin-left: 4.6875rem;
    padding-bottom: 0.9375rem;
  }
  .profile-header-info h4 {
    margin: 0 0 0.3125rem;
  }
  .profile-header .profile-header-tab {
    white-space: nowrap;
    overflow: scroll;
    padding: 0;
  }
  .profile-container {
    padding: 0.9375rem 0.9375rem 3.6875rem;
  }
  .friend-list > li {
    float: none;
    width: auto;
  }
}
.profile-info-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.friend-list,
.img-grid-list {
  margin: -1px;
  list-style-type: none;
}
.profile-info-list > li.title {
  font-size: 0.625rem;
  font-weight: 700;
  color: #8a8a8f;
  padding: 0 0 0.3125rem;
}
.profile-info-list > li + li.title {
  padding-top: 1.5625rem;
}
.profile-info-list > li {
  padding: 0.625rem 0;
}
.profile-info-list > li .field {
  font-weight: 700;
}
.profile-info-list > li .value {
  color: #666;
}
.profile-info-list > li.img-list a {
  display: inline-block;
}
.profile-info-list > li.img-list a img {
  max-width: 2.25rem;
  border-radius: 2.5rem;
}
.coming-soon-cover img,
.email-detail-attachment .email-attachment .document-file img,
.email-sender-img img,
.friend-list .friend-img img,
.profile-header-img img {
  max-width: 100%;
}
.table.table-profile th {
  border: none;
  color: #000;
  padding-bottom: 0.3125rem;
  padding-top: 0;
}
.table.table-profile td {
  border-color: #c8c7cc;
}
.table.table-profile tbody + thead > tr > th {
  padding-top: 1.5625rem;
}
.table.table-profile .field {
  color: #666;
  font-weight: 600;
  width: 25%;
  text-align: right;
}
.table.table-profile .value {
  font-weight: 500;
}

.friend-list {
  padding: 0;
}
.friend-list > li {
  float: left;
  width: 50%;
}
.friend-list > li > a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 0.625rem;
  margin: 1px;
  background: #fff;
}
.friend-list > li > a:after,
.friend-list > li > a:before {
  content: "";
  display: table;
  clear: both;
}
.friend-list .friend-img {
  float: left;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  background: #efeff4;
}
.friend-list .friend-info {
  margin-left: 3.625rem;
}
.friend-list .friend-info h4 {
  margin: 0.3125rem 0;
  font-size: 0.875rem;
  font-weight: 600;
}
.friend-list .friend-info p {
  color: #666;
  margin: 0;
}

.create_product {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.upload {
  max-width: 450px;
  height: 500px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 20px;
  position: relative;
}

#file-up {
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
}

#file-up::before {
  content: "+";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  color: rgb(250, 200, 107);
  font-size: 17rem;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

#file_img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}

#file_img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

#file_img span {
  position: absolute;
  top: -13px;
  right: -13px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 6px 10px;
  cursor: pointer;
  font-weight: 900;
  color: darkred;
}

.create_product form {
  max-width: 500px;
  min-width: 290px;
  width: 100%;
  margin: 15px 30px;
}

.create_product form .row {
  width: 100%;
  margin: 15px 0;
}

.create_product form input,
textarea {
  width: 100%;
  min-height: 40px;
  padding: 0 5px;
}
.create_product form button {
  width: 200px;
  height: 40px;
  background: #555;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}

#team {
  padding: 60px 0;
  text-align: center;
  background-color: #6f85ff;
  color: #fff;
}
#team h2 {
  position: relative;
  padding: 0px 0px 15px;
}
#team p {
  font-size: 15px;
  font-style: italic;
  padding: 0px;
  margin: 25px 0px 40px;
}
#team h2::after {
  content: "";
  border-bottom: 2px solid #fff;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 90px;
  margin: 0 auto;
  display: block;
}
#team .member {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 15px 0px 15px 0px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
}
#team .member .member-info {
  display: block;
  position: absolute;
  bottom: 0px;
  left: -200px;
  transition: 0.4s;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.4);
}
#team .member:hover .member-info {
  left: 0px;
  right: 0px;
}
#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}
#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}
#team .member .social-links {
  margin-top: 15px;
}
#team .member .social-links a {
  transition: none;
  color: #fff;
}
#team .member .social-links a:hover {
  color: #ffc107;
}
#team .member .social-links i {
  font-size: 18px;
  margin: 0 2px;
}

.detail {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 50px;
  font-size: 150%;
}

.detail img {
  max-width: 400px;
  width: 100%;
  margin: 10px 20px;
  height: 450px;
  object-fit: cover;
  display: block;
}

.box-detail {
  max-width: 500px;
  width: 100%;
  margin: 20px;
}

.box-detail .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-detail h2 {
  text-transform: uppercase;
  color: darkblue;
  letter-spacing: 2px;
  font-weight: 2rem;
}

.box-detail p {
  line-height: 1.5;
  margin: 10px 0;
  opacity: 0.8;
}

.box-detail .cart {
  background: #333;
  color: white;
  margin-top: 10px;
  padding: 10px 25px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* @font-face {
  font-family: "Oregon";
  src: url("../../../Fonts/Oregon_LDO/Oregon_LDO.ttf");
}
@font-face {
  font-family: "NewYork";
  src: url("../../../Fonts/newyork/newyork.woff");
} */
.history-page {
  overflow-x: auto;
  margin-top: 135px;
  height: 100%;
  min-height: 90vh;
}
.history-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 0px 20px;
}
.history-title-wrapper h1 {
  font-family: "NewYork";
  font-weight: 600;
}
.history-title-wrapper h2 {
  font-family: "Oregon";
  font-size: 1.2rem;
}
/*details container*/
.history-details-wrapper {
  padding: 0px 20px;
}
.history-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  padding: 20px 0px;
  border-top: 1px solid #0000001e;
}
.history-details-container h2 {
  font-size: 0.9rem;
}
.history-details-container h3 {
  font-size: 1rem;
  font-weight: 400;
}
.history-details-container a:hover {
  text-decoration: none;
}
.history-details {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .history-details-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 20px;
    grid-gap: 10px;
    gap: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .history-title-wrapper h1 {
    font-size: 2rem;
    padding-right: 50px;
  }
  .history-title-wrapper h2 {
    font-size: 1rem;
  }
}
/*
.history-page h2, h4 {
  text-align: center;
  margin: 20px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.history-page table {
  margin: auto;
  width: 100%;
}
.history-page table, th, tr, td {
  border: 1px solid #ddd;
  border-collapse: collapse;
}
th, td {
  text-align: center;
  padding: 10px;
  text-transform: capitalize;
}
table a {
  color: rgb(3, 165, 206);
}
.history-page img {
  width: 70px;
  height: 100px;
  object-fit: cover;
} */

/* @font-face {
  font-family: "Oregon";
  src: url("../../../Fonts/Oregon_LDO/Oregon_LDO.ttf");
}
@font-face {
  font-family: "NewYork";
  src: url("../../../Fonts/newyork/newyork.woff");
} */

.card-cart {
  height: 100vh;
  padding-top: 20%;
}

/* overall wrapper */
.cart-wrapper {
  height: 80vh;
  padding-top: 10%;
}

/* title header wrapper */
.cart-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}
.cart-title-wrapper h1 {
  font-family: "NewYork";
  font-weight: 600;
}
.cart-title-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #000000;
  padding: 5px 10px;
  transition: 0.5s background-color;
  text-decoration: none;
  font-family: "Oregon";
  text-decoration: none;
  color: #000000;
  width: auto;
  font-size: 0.9rem;
  letter-spacing: 1px;
}
.cart-title-wrapper a:hover {
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
}
/* product information wrapper */
.cart-details-wrapper {
  padding: 0px 20px;
  -webkit-user-select: none;
          user-select: none;
}
.cart-details-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #00000031;
  padding: 20px 0px;
  grid-gap: 20px;
  gap: 20px;
}
.product-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  max-width: 500px;
}
.product-container img {
  height: 135px;
  width: 135px;
  min-width: 135px;
  object-fit: cover;
  object-position: left top;
}
.cart-details-container h3 {
  font-size: 0.8rem;
  font-family: "Oregon";
  letter-spacing: 1px;
}
.cart-details-container h2 {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Oregon";
  letter-spacing: 1px;
}
.cart-details-container p {
  font-family: "Oregon";
  letter-spacing: 1px;
}
.box-detail-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.box-detail-product div span {
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oregon";
}
.box-detail-product div {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.box-detail-delete {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Oregon";
}
.box-detail-delete:hover {
  color: crimson;
  border-bottom: 1px solid crimson;
}
/* total products div */
.total-products-wrapper {
  padding: 0px 20px 20px 20px;
}
.total-products-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px 0px;
  border-top: 1px solid #00000031;
  border-bottom: 1px solid #00000031;
}
.total-products-container span {
  color: #26c917;
}

@media only screen and (max-width: 900px) {
  .cart-details-container {
    flex-direction: column;
  }
  .box-detail-product {
    width: 100%;
    flex-direction: row;
  }
  .box-detail-product h3 {
    max-width: 145px;
    min-width: 145px;
  }
}
@media only screen and (max-width: 400px) {
  .cart-title-wrapper a {
    font-size: 0.6rem;
    padding: 5px;
  }
  .cart-title-wrapper h1 {
    font-size: 2rem;
  }
}
/*
.cart {
    position: relative;
    transform: scaleY(0.98);
}
.amount span {
    color: crimson;
    padding: 0 20px;
}
.amount button {
    width: 40px;
    height: 40px;
    border: 1px solid #777;
}
.delete {
    position: absolute;
    top: 0;
    right: 5px;
    color: crimson;
    font-weight: 900;
    cursor: pointer;
}
.total {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total h3 {
    color: crimson;
}
.mt-100 {
    margin-top: 100px
}
.cart-card {
    margin-bottom: 30px;
    border: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 8px;
    -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05)
}
.cart-card .cart-card-body {
    padding: 30px;
    background-color: transparent
} */

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #4466f2 !important;
  border-color: #4466f2 !important;
}

