@media only screen and (min-width: 768px) {
  .twitter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.twitter-timeline {
  height: 90vh !important;
}
