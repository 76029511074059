.tech-container {
  background-color: rgb(35, 41, 47);
  background-color: whitesmoke;
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url("../../Assets/Images/keyboard-min.jpeg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 40vh;
}

.tech-title {
  font-size: 8rem;
  margin: 0 auto;
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #206a5d;
  letter-spacing: 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 6%;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-150px * 7));
    transform: translateX(calc(-150px * 7));
  }
}
.client-slider {
  background: whitesmoke;
  background: transparent;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.client-slider::before,
.client-slider::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1a1f23),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(
    to right,
    #1a1f23 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.client-slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.client-slider::before {
  left: 0;
  top: 0;
}
.client-slider .client-slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: -webkit-box;
  display: flex;
  width: calc(250px * 11);
}
.client-slider .client-slide {
  height: 100px;
}

.pinklemonade {
  border-radius: 12px;
}

.client-slide {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 768px) {
  .tech-title {
    font-size: 3rem;
  }
}

@media only screen and (max-device-width: 1024px) {
  .client-slider {
    top: 50%;
  }
}
@media only screen and (max-device-width: 768px) {
  .client-slider {
    top: 35%;
  }
}
