/*──────────────────
     Skeleton
──────────────────*/

.post {
  display: flex;
  width: 100%;
}

.post .left-col {
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.5rem;
}

.post .avatar {
  width: 75px;
  height: 75px;
  background-color: #eaeaea;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.post .avatar-skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
}

.post .avatar img {
  width: 70%;
  height: 70%;
}

.post .right-col {
  flex: 1;
}

/*──────────────────
     Login
──────────────────*/

.login span:hover {
  text-decoration: underline;
  cursor: pointer;
}

/*──────────────────
      Tabs
──────────────────*/

/* Tabs*/
#tabs {
  padding: 60px 0;
}
#tabs {
  color: #eee;
}
#tabs h6.section-title {
  color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.tab-active {
  color: #f3f3f3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid !important;
  font-size: 1.2em;
  font-weight: bold;
  opacity: 0.5;
  text-decoration: none;
  padding: 2%;
}

#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #eee;
  font-size: 1.2em;
}

.blog-tabs {
  display: flex;
  justify-content: space-between;
}

.nav-item .nav-link {
  text-decoration: none;
}

/*──────────────────
      articles
──────────────────*/

.article-box {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 2rem;
  margin-top: 5rem;
  padding: 0% 4% 10% 4%;
}

.article-header {
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url("../../Assets/Images/project1-min.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 40vh;
}
.article-container {
  text-align: center;
  color: white;
  background-color: #1a1e23;
  overflow: hidden;
}

.articles-header {
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 5rem;
}
.article-content {
  background-color: #23292f;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

#articles {
  margin-top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.article-category-container {
  margin-top: 4rem;
  background-color: #23292f;
}

.article-categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 100%;
}
.tag-category {
  list-style: none;
  padding: 5rem;
  margin: 0%;
  background-color: lightgray;
  border: 1px solid black;
}
.tag-category:hover {
  color: #1a1e23;
  cursor: pointer;
}
.article-card {
  width: 100%;
  height: fit-content;
  margin-left: 1rem !important;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}

.article-card .work-content {
  text-align: center;
  padding: 10px 5px;
  font-size: 2rem;
  color: white;
  font-family: var(--main-font-family);
  cursor: pointer;
}

.article-line {
  background-color: lightgray;
  opacity: 0.5;
  width: 95%;
  margin: 2rem;
}
.popular {
  background-color: #23292f;
  text-align: center;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 1.5rem;
  color: white;
  font-family: var(--main-font-family);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 55%;
  border-radius: 12px;
}
.page-link {
  background-color: #23292f !important;
  color: white !important;
}
.popular-articles {
  margin: auto;
}

.article-card-header {
  color: white;
  font-size: 4rem;
  text-decoration: none;
}

.article-card-header:hover {
  text-decoration: none;
  transition: 0.25s;
  color: #204740;
}

.article-card:hover {
  box-shadow: 0 0 1.5rem gray;
}

.article-card-meta {
  font-size: 2rem;
}

.popular-link,
.popular-articles a {
  text-decoration: none;
  color: white;
}

.popular-link:hover,
.popular-articles a:hover {
  text-decoration: underline;
  color: #204740;
}

.articleList {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-column-start: 1;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
}

.article-search {
  background-color: #1a1e23;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 50%;
  text-align: center;
  font-size: 3rem;
  margin-top: 5rem;
  color: white;
}

.article-search:focus {
  outline: none;
}
/* Category Tags */
.main-tag-container-article {
  list-style: none;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.main-tag-article:hover {
  text-decoration: none;
  color: white;
  opacity: 0.6;
  cursor: default;
}
.main-tag-article::before {
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 2%;
  left: 10px;
  position: absolute;
  width: 2%;
  top: 10px;
  background-color: white;
}

.main-category {
  display: flex;
  justify-content: center;
  align-items: centers;
}
.main-category h3 {
  margin-right: 20px;
}
.main-tag-article {
  border: 2px solid #fff;
  color: white;
  opacity: 0.6;
  box-sizing: inherit;
  cursor: pointer;
  border-radius: 12px !important;
  display: inline-block;
  font-size: 1em;
  padding: 0 22px 0 25px;
  margin: 0 10px 10px 0;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  background-color: gray;
  font-size: larger;
  height: 26px;
  line-height: 26px;
  position: relative;
  -webkit-transition: color 0.2s;
}

/* Category Tags */

.article-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 12px;
}

.article-btn-info {
  border: 2px solid #fff;
  color: white;
  box-sizing: inherit;
  cursor: pointer;
  font-size: 0.8em;
  padding: 10px 20px;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  text-transform: uppercase;
}
.article-btn {
  animation: header-btn 10s steps(1) -1s normal;
}
@keyframes header-btn {
  0% {
    left: 250%;
  }
  100% {
    left: 0%;
  }
}

.article-btn-info:hover {
  background-color: #204740;
  text-decoration: none;
  border-color: #204740;
  font-size: 0.8em;
  transition: 0.5s color ease;
  color: rgb(235, 183, 65);
}

/*
@media screen and (min-width: 768px) {
    .articleList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1.5rem ;
    }
} */

/* @media screen and (min-width: 992px) {
    .articleList {
        width: 85vw;
        grid-template-columns: repeat(2, 1fr);
    }
} */

/* @media screen and (min-width: 2000px) {
    .articleList {
        grid-template-columns: repeat(2, 1fr);
	}

} */

.blog-content {
  display: flex;
  background-color: white;
  width: 100%;
  font-family: sans-serif;
}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 60px;
}

.top-header.menu-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 20px;
}

.top-header.fix-search {
  background: #eee;
}

.search {
  position: absolute;
  top: 155px;
  left: 20px;
  right: 20px;
}

.search input {
  width: 265px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 15px;
  transition: width 0.2s;
  -webkit-appearance: none;
}

.search.fix-search {
  position: fixed;
  top: 10px;
}

.search.fix-search input {
  width: 250px;
}

.top {
  height: 250px;
  padding-top: 40px;
}
.hero {
  width: 200px;
  height: 100px;
  background: #ccc;
  margin: 0 auto;
}

.blog-main p {
  font-size: 2rem;
}

.blog-sub {
  background-color: white !important;
}

.blog-card p {
  color: black;
}
.blog-feature {
  opacity: 0.6;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.03rem;
}
.blog-icon {
  font-size: 2.3rem;
}
.blog-heading {
  margin: 0 auto;
  display: contents;
}

#terminal {
  align-self: flex-start;
}

.blog-categories {
  position: relative;
  top: 42%;
  right: 30%;
}
.blog-categories h6 {
  text-transform: uppercase;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .popular,
  .subscribe-container,
  .article-sidebar-container {
    display: none !important;
  }

  .article-box {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .article-sidebar {
    display: none;
  }
  .article-box {
    grid-template-columns: 1fr;
    padding: 4%;
  }
  .article-card {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .article-box {
    padding: 8%;
  }

  .article-header,
  .blog-tabs,
  #tabs {
    display: none;
  }
}
