#team {
  padding: 60px 0;
  text-align: center;
  background-color: #6f85ff;
  color: #fff;
}
#team h2 {
  position: relative;
  padding: 0px 0px 15px;
}
#team p {
  font-size: 15px;
  font-style: italic;
  padding: 0px;
  margin: 25px 0px 40px;
}
#team h2::after {
  content: "";
  border-bottom: 2px solid #fff;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 90px;
  margin: 0 auto;
  display: block;
}
#team .member {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 15px 0px 15px 0px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
}
#team .member .member-info {
  display: block;
  position: absolute;
  bottom: 0px;
  left: -200px;
  transition: 0.4s;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.4);
}
#team .member:hover .member-info {
  left: 0px;
  right: 0px;
}
#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}
#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}
#team .member .social-links {
  margin-top: 15px;
}
#team .member .social-links a {
  transition: none;
  color: #fff;
}
#team .member .social-links a:hover {
  color: #ffc107;
}
#team .member .social-links i {
  font-size: 18px;
  margin: 0 2px;
}
