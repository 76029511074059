.create_article {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.mb {
  margin-bottom: 10px;
}
.mauto {
  margin: 0 auto;
}

.maxwidth {
  width: 100vw;
}

.upload {
  /* max-width: 450px; */
  height: 250px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
  /* margin: 20px; */
  position: relative;
}

#file_up {
  position: relative;
  height: 100%;
  width: 100%;
  outline: none;
}

#file_up::before {
  content: "+";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -20%;
  left: 0;
  background-color: white;
  color: rgb(250, 200, 107);
  font-size: 17rem;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

#file_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}

#file_img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

#file_img span {
  position: absolute;
  top: -13px;
  right: 13px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 900;
  color: crimson;
}

.textarea,
.preview,
.markdown {
  min-height: 400px;
  border: 1px solid lightgray;
  min-width: 400px;
  padding: 20px;
}

/* CONTEXTUAL HELP */

.qs {
  background-color: #02bdda;
  border-radius: 16px;
  color: #e3fbff;
  cursor: default;
  display: inline-block;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  position: relative;
  text-align: center;
  width: 20px;
}

.qs .popover {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  top: -7rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #fff;
  display: none;
  font-size: 12px;
  font-family: 'Helvetica', sans-serif;
  left: -105px;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  height: fit-content;
  z-index: 4;
}

.qs .popover:before {
  border-top: 7px solid rgba(0, 0, 0, 0.85);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  content: '';
  display: block;
  left: 50%;
  margin-left: 7px;
  position: absolute;
}

.qs:hover .popover {
  display: block;
  -webkit-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -moz-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -ms-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes move-up {
  from {
    bottom: 30px;
  }

  to {
    bottom: 42px;
  }
}

@-moz-keyframes move-up {
  from {
    bottom: 30px;
  }

  to {
    bottom: 42px;
  }
}

@-ms-keyframes move-up {
  from {
    bottom: 30px;
  }

  to {
    bottom: 42px;
  }
}

/* OPEN API */

.grid-main {
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px;
  margin-top: 40px;
}

.grid-child {
  border: 2px solid whitesmoke;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.grid-child:hover {
  background-color: whitesmoke;
  color: #212121;
}

.grid-child h3 {
  font-family: Poppins;
  font-weight: 700;
}

.grid-child p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
}

.text-area {
  font-size: 20px;
  padding: 10px;
  font-family: Poppins;
}

.action-btn {
  width: 200px;
  height: 50px;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.result-text {
  font-family: Poppins;
  font-size: 25px;
  line-height: 2.5rem;
}