.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 99;
}

.logo-loader {
  height: 35rem;
  width: 35rem;
}

.loading-bar {
  width: 30rem;
  height: 2px;
  background: #cfcfcf;
  margin: 22px;
  position: relative;
  overflow: hidden;
}

.loading-bar::before {
  content: "";
  width: 68px;
  height: 2px;
  background: #050a30;
  position: absolute;
  left: -34px;
  animation: bluebar 1.5s infinite ease;
}

@keyframes bluebar {
  50% {
    left: 25rem;
  }
}
