.subscribe-link {
  margin: 0 auto;
  border-radius: 12px;
}

.subscribe-container {
  display: flex;
  flex-direction: column;
  background: rgb(35, 41, 47);
  color: white;
  margin-bottom: 15rem;
  padding: 1rem;
  border-radius: 12px;
  height: auto;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.subscribe-container h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.subscribe-container p {
  text-align: center;
  margin: 30px;
  color: white;
  font-size: 1.7rem;
}

.subscribe-container div input {
  margin: 10px;
  padding: 10px;
  border-radius: 12px;
  width: 70%;
  border-color: white;
}

.subscribe-container div input::placeholder {
  font-size: 1.5rem;
}
