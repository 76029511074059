/* Project Containers */

.project-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  margin-top: 5rem;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-link {
  color: #fff;
  border-color: #fff;
}

.portfolio-link:hover {
  text-decoration: none;
  border-color: #206a5d;
}

/* Project Containers */

@media only screen and (min-device-width: 375px) and (max-device-width: 600px) {
  .p-container {
    grid-template-columns: 1fr !important;
  }
}
