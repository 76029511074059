.notification-wrapper {
  position: fixed;
  top: 5%;
  right: 10px;
  width: 300px;
  z-index: 1;
}

.notification-item {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification-item.exit {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
}

.notification-item .bar {
  height: 10px;
}

.notification-item.success .bar {
  background-color: #65d266;
}

.notification-item.error .bar {
  background-color: red;
}
