.wrapper {
  padding-top: 130px;
}
.shop-container {
  display: flex;
  grid-template-columns: 30rem auto;
  width: 100%;
}

.sidebar {
  width: 100%;
  margin: 0;
}
