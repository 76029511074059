.skeleton {
  background: #ddd;
  margin: 10px 0;
  border-radius: 4px;
}

.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  position: relative;
  background: #f2f2f2;
  border-radius: 1%;
  animation: loading 2.5s infinite;
}

.skeleton-wrapper.profile {
  height: 100vh;
}

.skeleton-wrapper.profile .skeleton-card {
  padding: 5%;
}

.skeleton-profile .skeleton.avatar {
  width: 300px;
  height: 300px;
  padding-bottom: 5%;
}

.skeleton.text {
  width: 100%;
  height: 12px;
}

.skeleton.title {
  width: 50%;
  height: 20px;
  margin-bottom: 15px;
}

.skeleton.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.skeleton-blog {
  background: #23292f;
  border-radius: 5%;
  height: 100%;
}

.shimmer {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5%;
  /* transform: skewX(-20deg); */
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transition: translateX(-150%);
  }
  50% {
    transition: translateX(-60%);
  }
  100% {
    transition: translateX(150%);
  }
}
