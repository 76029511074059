.products {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 90vh;
  padding: 0px 20px 0px 20px;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

@import url("./ProductItem.css");

.delete-all {
  text-align: right;
  margin: 20px;
}

.delete-all input {
  height: 25px;
  width: 25px;
  transform: translateY(5px);
  margin: 0 15px;
}

.delete-all span {
  text-transform: uppercase;
  color: rgb(3, 165, 206);
  letter-spacing: 1.3px;
}

.delete-all button {
  border: 1px solid crimson;
  padding: 10px 25px;
  color: crimson;
  text-transform: uppercase;
}

.filter_menu {
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
}

.filter_menu section,
input {
  border: 1px solid #ccc;
  padding: 0 10px;
  outline: none;
  height: 40px;
}

.filter_menu input {
  flex: 1;
  margin: 0 10px;
}

.load_more {
  text-align: center;
}

.load_more button {
  padding: 10px 25px;
  margin-bottom: 20px;
  border: 1px solid #555;
  text-transform: capitalize;
}
