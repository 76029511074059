

.text-block-inner {
  width: 100%;
  margin: 0 auto;
}

.text-block-group {
  list-style-type: none;
}

.text-block-paragraph + .text-block-paragraph {
  margin-top: 10px;
}

.text-block-group:first-child {
  margin-top: 0;
}

.text-block-group:last-child {
  margin-bottom: 0;
}

.text-block-group:nth-child(even) {
  text-align: right;
}

.text-block-paragraph a {
  color: #fff;
}

.text-block-paragraph a:hover {
  text-decoration: none;
}

[data-js="reveal"] {
}

[data-reveal="content"] {
  display: inline-block;
  position: relative;
}

[data-reveal="cover"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 1;
}

[data-reveal="text"] {
  opacity: 0;
}

@keyframes reveal-cover {
    0%   { width: 0;left: 0; }
    44%  { width: 100%;left: 0; }
    54%  { width: 100%;left: 0;  }
    100% { width: 0;left: 100%; }
}

@keyframes reveal-text {
    0%   { opacity: 0; }
    44%  { opacity: 0; }
    54%  { opacity: 1; }
}

[data-js="reveal"].loaded [data-reveal="cover"] {
  animation: reveal-cover 1.5s ease-in-out;
}

[data-js="reveal"].loaded [data-reveal="text"] {
  opacity: 1;
  animation: reveal-text 1.5s ease-in-out;
}
