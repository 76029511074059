.social_footer_ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 25%;
  margin-right: 25%;
}

.social_footer_ul li {
  padding-left: 1.5rem;
  padding-top: 3rem;
  float: left;
  margin: 0 auto;
  list-style: none;
}

.social_footer_ul li a {
  font-size: 1.5rem;
  color: #ccc;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 50%;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .social_footer_ul li i {
    font-size: 2rem;
  }
  .social_footer_ul li {
    padding-left: 16px;
  }
  .social_footer_ul {
    padding: 0;
  }
}
