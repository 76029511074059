.col_white_amrc {
  color: #fff;
}

footer {
  width: 100%;
  background-color: #1a1e23;
  min-height: 250px;
  padding: 10px 0px 25px 0px;
}

.pt2 {
  padding-top: 40px;
  margin-bottom: 20px;
}

footer p {
  font-size: 1.8em;
  color: #ccc;
  padding-bottom: 0px;
  margin-bottom: 8px;
  display: flex;
  gap: 4%;
  align-items: center;
}

.mb10 {
  padding-bottom: 15px;
}

.footer_h5 {
  font-size: 2.5rem;
}

.footer_ul_amrc {
  margin: 0px;
  list-style-type: none;
  font-size: 1.5em;
  padding: 0px 0px 10px 0px;
}

.footer_ul_amrc li {
  padding: 0px 0px 5px 0px;
}

.footer_ul_amrc li a {
  color: #ccc;
}

.footer_ul_amrc li a:hover {
  color: #fff;
  text-decoration: none;
}

.copyright {
  display: flex;
  justify-content: center;
}

.fleft {
  float: left;
}

.padding-right {
  padding-right: 10px;
}

.footer_ul2_amrc {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}

.footer_ul2_amrc li {
  display: flex;
  align-items: baseline;
}

.footer_ul2_amrc li p {
  display: table;
}

.footer_ul2_amrc li a:hover {
  text-decoration: none;
}

.footer_ul2_amrc li i {
  margin-top: 5px;
}

.bottom_border {
  border-bottom: 1px solid #323f45;
  padding-bottom: 20px;
  width: 100%;
}

.social_footer_ul li a {
  display: flex;
}

.foote_bottom_ul_amrc {
  list-style-type: none;
  padding: 0px;
  display: table;
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}
.foote_bottom_ul_amrc li {
  display: inline;
  font-size: 1.5rem;
}

.foote_bottom_ul_amrc li a {
  color: #999;
  margin: 0 12px;
}

.dompl {
  margin-left: 50px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .twitter-footer {
    display: none;
  }

  .social_footer_ul {
    margin-left: 10% !important;
  }
}

@media only screen and (max-width: 480px) {
  .dompl {
    margin: 0%;
  }
}
