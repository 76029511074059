.header-nav {
  background-color: #1a1e23;
}

.nav-logo {
  width: 10rem;
}

.nav-link {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  color: white !important;
  font-size: 2.5rem;
  margin: 2rem;
}

.dropdown-menu {
  background-color: #1a1e23 !important;
}

.nav-link:focus,
.nav-link:hover,
.nav-link:active {
  padding: 5px 7px;
  border-color: rgba(255, 255, 255, 1);
  outline: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  transition: 0.5s color ease;
  color: #81b3d2;
}

.nav-combo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
}

.left-nav {
  font-weight: 700;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Lato", sans-serif;
  list-style-type: none;
}

.dropdown-menu {
  overflow-x: hidden;
}

.burger-nav {
  display: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
  color: rgb(235, 183, 65) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #206a5d !important;
  background-color: transparent;
}

/* Small devices (portrait tablets and large phones, 480px and up) */

@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .burger-nav {
    display: flex;
    width: 100%;
    height: 8vh;
    background-color: #1a1e23;
  }
  .nav-logo,
  .nav-title {
    display: none;
  }
  .nav-link {
    text-align: left;
    width: 100%;
    margin: 0;
    color: white;
    padding: 10px;
    border-bottom: 1px solid #404040;
  }
  .left-nav {
    overflow: hidden;
    display: block;
    height: 0;
  }
  .left-nav.open {
    height: auto;
  }
  .nav-combo {
    justify-content: start;
  }
}
