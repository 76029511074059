.create_product {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.upload {
  max-width: 450px;
  height: 500px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 20px;
  position: relative;
}

#file-up {
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
}

#file-up::before {
  content: "+";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  color: rgb(250, 200, 107);
  font-size: 17rem;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

#file_img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}

#file_img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

#file_img span {
  position: absolute;
  top: -13px;
  right: -13px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 6px 10px;
  cursor: pointer;
  font-weight: 900;
  color: darkred;
}

.create_product form {
  max-width: 500px;
  min-width: 290px;
  width: 100%;
  margin: 15px 30px;
}

.create_product form .row {
  width: 100%;
  margin: 15px 0;
}

.create_product form input,
textarea {
  width: 100%;
  min-height: 40px;
  padding: 0 5px;
}
.create_product form button {
  width: 200px;
  height: 40px;
  background: #555;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
