.home-page {
  overflow: hidden;
  background-color: #1a1e23;
}

.homepage-combo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.testomonies,
.socialmedia {
  display: flex;
  align-items: center;
  background-color: #1a1e23;
}

.testimonies-group,
.contact-group {
  background-color: rgb(35, 41, 47);
  background-color: whitesmoke;
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url("../../Assets/Images/cpu-min.jpeg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.testimonies-group h2 {
  display: flex;
  justify-content: center;
  margin: auto;
}

.contact-group {
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url("../../Assets/Images/cpu-min.jpeg");
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vh;
  justify-content: center;
}

.subTitle {
  font-size: 8rem;
  margin: 0 auto;
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #206a5d;
  letter-spacing: 1rem;
  display: flex;
  align-items: center;
  padding: 10rem;
}

.email {
  font-size: 8rem !important;
}

.email:hover {
  font-size: 9rem !important;
  cursor: pointer;
  filter: drop-shadow(0 0 1.5rem white);
}

.header-button {
  border: 2px solid #fff;
  color: white;
  box-sizing: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 1.8em;
  margin-bottom: 10px;
  padding: 10px 10px;
  background-color: transparent;
  transition: all 0.5s;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}

.header-button {
  animation: header-btn 10s steps(1) -1s normal;
}
@keyframes header-btn {
  0% {
    left: 250%;
  }
  100% {
    left: 0%;
  }
}

.header-button:hover {
  background-color: #204740;
  text-decoration: none;
  transition: 0.5s color ease;
  color: #fff;
}

.header-username,
.header-username2,
.header-career,
.header-scroll {
  color: white;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.header-scroll {
  position: fixed;
  top: 95%;
  width: 100%;
  margin-top: 20rem;
}

/* Image Overlay End */

.projects {
  width: 100%;
}

.projects h1,
.technologies h1,
.color h1,
.articles h1,
.socialmedia h1,
.open-section h1 {
  color: white;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  font-size: 4rem;
  letter-spacing: 1rem;
  line-height: 40px;
  margin: 0;
}

.technologies {
  background-color: #1a1e23;
}

.contact {
  background-color: #1a1e23;
  padding-top: 2.5rem;
  margin-top: 2rem;
}

.btn-input {
  margin-top: 1rem;
  font-size: 1rem;
  border-color: #206a5d;
  border: #206a5d;
  text-decoration: none;
  background-color: #206a5d;
  color: #ebecf1;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
  border-radius: 8%;
}

.btn-input:hover {
  margin-top: 1rem;
  font-size: 1.2rem;
  border-color: #206a5d;
  border: #206a5d;
  text-decoration: underline;
  background-color: #206a5d;
  color: #ebecf1;
  padding: 4px 8px 4px 8px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
  border-radius: 8%;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 1180px) {
  .homepage-combo {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .socialmedia {
    width: 80%;
  }
  .testomonies {
    width: 85%;
  }
  .subTitle {
    font-size: 3.5rem;
  }

  .header-button {
    margin: auto !important;
    width: 40% !important;
  }

  .contact-group h2 {
    font-size: 3.5rem !important;
  }
}
