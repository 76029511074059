.pagination {
  font-size: 2rem !important;
}

.page-item.active .page-link {
  border-color: white !important;
  background-color: #206a5d !important;
}

.disabled {
  opacity: 0.5;
}
