.testimonial-carousel {
  width: 650px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.testimonial-carousel .item {
  color: #999;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  min-height: 340px;
}

.testimonial-carousel .img-box {
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border-radius: 50%;
}

.testimonial-carousel .img-box img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}

.testimonial-carousel .testimonial-descp {
  padding: 30px 0 10px;
}

.testimonial-descp {
  color: #fff;
  font-size: 2rem;
  width: 90%;
  text-align: center;
}

.testimonial-carousel .testimonial-title {
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

.testimonial-carousel .testimonial-title b {
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
  display: block;
  padding-bottom: 5px;
}
.testimonial-carousel .carousel-control {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #999;
  text-shadow: none;
  top: 4px;
}

.testimonial-carousel-control i {
  font-size: 1.3em;
  margin-right: 2px;
}

.carousel-control.left {
  left: auto;
  right: 40px;
}

.carousel-control.right i {
  margin-right: -2px;
}

.carousel .carousel-indicators {
  bottom: 15px;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 11px;
  height: 11px;
  margin: 1px 5px;
  border-radius: 50%;
}

.carousel-indicators li {
  border-color: transparent;
}

.carousel-indicators li.active {
  border: none;
  background: #888;
}

.carousel-indicators {
  margin: 0 auto !important;
  width: 100%;
}
