/* @font-face {
  font-family: "Oregon";
  src: url("../../../Fonts/Oregon_LDO/Oregon_LDO.ttf");
}
@font-face {
  font-family: "NewYork";
  src: url("../../../Fonts/newyork/newyork.woff");
} */

.card-cart {
  height: 100vh;
  padding-top: 20%;
}

/* overall wrapper */
.cart-wrapper {
  height: 80vh;
  padding-top: 10%;
}

/* title header wrapper */
.cart-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}
.cart-title-wrapper h1 {
  font-family: "NewYork";
  font-weight: 600;
}
.cart-title-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #000000;
  padding: 5px 10px;
  transition: 0.5s background-color;
  text-decoration: none;
  font-family: "Oregon";
  text-decoration: none;
  color: #000000;
  width: auto;
  font-size: 0.9rem;
  letter-spacing: 1px;
}
.cart-title-wrapper a:hover {
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
}
/* product information wrapper */
.cart-details-wrapper {
  padding: 0px 20px;
  user-select: none;
}
.cart-details-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #00000031;
  padding: 20px 0px;
  gap: 20px;
}
.product-container {
  display: flex;
  gap: 20px;
  max-width: 500px;
}
.product-container img {
  height: 135px;
  width: 135px;
  min-width: 135px;
  object-fit: cover;
  object-position: left top;
}
.cart-details-container h3 {
  font-size: 0.8rem;
  font-family: "Oregon";
  letter-spacing: 1px;
}
.cart-details-container h2 {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Oregon";
  letter-spacing: 1px;
}
.cart-details-container p {
  font-family: "Oregon";
  letter-spacing: 1px;
}
.box-detail-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.box-detail-product div span {
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oregon";
}
.box-detail-product div {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.box-detail-delete {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  transition: 0.5s;
  font-family: "Oregon";
}
.box-detail-delete:hover {
  color: crimson;
  border-bottom: 1px solid crimson;
}
/* total products div */
.total-products-wrapper {
  padding: 0px 20px 20px 20px;
}
.total-products-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  padding: 20px 0px;
  border-top: 1px solid #00000031;
  border-bottom: 1px solid #00000031;
}
.total-products-container span {
  color: #26c917;
}

@media only screen and (max-width: 900px) {
  .cart-details-container {
    flex-direction: column;
  }
  .box-detail-product {
    width: 100%;
    flex-direction: row;
  }
  .box-detail-product h3 {
    max-width: 145px;
    min-width: 145px;
  }
}
@media only screen and (max-width: 400px) {
  .cart-title-wrapper a {
    font-size: 0.6rem;
    padding: 5px;
  }
  .cart-title-wrapper h1 {
    font-size: 2rem;
  }
}
/*
.cart {
    position: relative;
    transform: scaleY(0.98);
}
.amount span {
    color: crimson;
    padding: 0 20px;
}
.amount button {
    width: 40px;
    height: 40px;
    border: 1px solid #777;
}
.delete {
    position: absolute;
    top: 0;
    right: 5px;
    color: crimson;
    font-weight: 900;
    cursor: pointer;
}
.total {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total h3 {
    color: crimson;
}
.mt-100 {
    margin-top: 100px
}
.cart-card {
    margin-bottom: 30px;
    border: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 8px;
    -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05)
}
.cart-card .cart-card-body {
    padding: 30px;
    background-color: transparent
} */

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #4466f2 !important;
  border-color: #4466f2 !important;
}
