.header2 {
  background-image: linear-gradient(
      to right bottom,
      rgba(119, 119, 119, 0.6),
      rgba(119, 119, 119, 0.6)
    ),
    url("../../Assets/Images/project1-min.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 40vh;
  margin: 0;
}
/* Tags */
.tags {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 1%;
  left: 10px;
  position: absolute;
  width: 1%;
  top: 10px;
}

.tag::after {
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: #206a5d;
  color: white;
}

.tag:hover::after {
  border-left-color: #206a5d;
}

/* Tags end */

.project-box {
  position: relative;
  left: 10%;
  background-color: white;
  width: 80%;
  display: flex;
  flex-direction: column;
}
.project-container {
  color: black;
  background-color: #1a1e23;
}
.project-desciption {
  position: relative;
  left: 0%;
}
@media only screen and (max-width: 480px) {
}
